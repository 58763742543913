import { Link } from 'react-router-dom';
import * as Icons from 'react-icons/fa';

export default function Dataset(props) {
	return (
		<div className={'relative py-32 p-site-x flex flex-col items-center justify-center bg-sky-700  ' + (props.className || '')} id='dataset'>

			<div className='max-w-boxed flex flex-col lg:flex-row items-center sm:items-start gap-8 sm:gap-16 text-white z-20'>

				<div className='flex flex-col gap-4'>
					<h1>Dataset</h1>
					<div className='w-full h-2 bg-gray-900' />
				</div>

				<div className='flex flex-col gap-8 sm:gap-4 items-start text-lg'>

					<div className='text-sm sm:text-base leading-6 sm:leading-8 text-center sm:text-left'>It means high quality data is necessary to build high quality algorithms. Unfortunately, the necessary datasets to conduct meta-communication analysis are not available publicly. This is the reason why we decided to build our own dataset. It would be nice if you could participate in the dataset, and help us to improve our algorithms.</div>

					<div className='w-full flex gap-1 items-center justify-center sm:justify-start font-extrabold hover:text-gray-900 transition'>

						<Link className='uppercase' to='/dataset-participation'>I would like to participate</Link>
						<Icons.FaChevronRight />

					</div>

				</div>

			</div>

		</div>
	);
}
