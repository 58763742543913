import nBossConnection from 'modules/base/service/nBossConnection';

class MyFileRepositoryService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'fileRepository';
	}

	/**
	 * @param {string} clientKey
	 * @return {Promise<String>}
	 */
	closeFileChunk(clientKey) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/closeFileChunk`, {
					clientKey
				})
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new MyFileRepositoryService();
