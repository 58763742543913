import nBossConnection from 'modules/base/service/nBossConnection';
import PersonalDataDto from 'dto/personalData/PersonalDataDto';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';

class PersonalDataService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'personalDataControl';
	}

	/**
	 * @param {PersonalDataDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, { dto })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {PersonalDataDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, { dto })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<PersonalDataDto>}
	 */
	getData() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getData`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	getFormData() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getFormData`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<number>}
	 */
	getLoggedInUserPersonalId() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getLoggedInUserPersonalId`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<Object>}
	 */
	close() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/close`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<boolean>}
	 */
	isFinished() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/isFinished`)
				.then(response => resolve(response.data))
				.catch(reject);
		});
	}
}

export default new PersonalDataService();
