import { motion } from 'framer-motion';
import { CgProfile, CgLogOut, CgSoftwareDownload } from 'react-icons/cg';

export default function DatasetProfileMenu(props) {
	return (
		<motion.div className="fixed h-screen top-0 left-0 z-50" transition="backIn" initial="visible" animate="visible" variants={{ visible: { x: 0 }, hidden: { x: '100%' } }}>
			<div className="absolute top-0 left-0 w-0 h-full flex items-center justify-center">
				<div className="relative w-full">
					<div className="absolute h-full left-0 flex flex-row items-center justify-center">
						<div className="flex flex-col rounded-r overflow-hidden divide-y divide-[rgba(255,255,255,0.2)]">
							<Button label="profile" icon={<CgProfile />} onClick={() => props.onMyProfile()} />

							<Button label="download dataset" icon={<CgSoftwareDownload />} onClick={() => props.onDownload()} />

							<Button label="logout" icon={<CgLogOut />} onClick={() => props.onLogout()} />
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}

function Button(props) {
	return (
		<button className={'min-h-20 min-w-20 p-4 shadow-xl transition text-white bg-sky-700 hover:bg-gray-900'} onClick={() => props.onClick()}>
			<div className="flex flex-col gap-2 items-center justify-center">
				<div className="text-2xl">{props.icon}</div>
				<div>
					<div className="leading-none text-xs font-extrabold uppercase">{props.label}</div>
				</div>
			</div>
		</button>
	);
}
