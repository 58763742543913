import nBossConnection from 'modules/base/service/nBossConnection';
import ContactDto from 'dto/contact/ContactDto';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';

class ContactService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'contactControl';
	}

	/**
	 * @param {ContactDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, { dto })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}
}

export default new ContactService();
