import _ from '@lodash';
import { useState, useRef, useEffect } from 'react';
import { MuseClient, zipSamples } from 'muse-js';
import { motion } from 'framer-motion';
import { share, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import MuseChart from '../demos/MuseChart';
import MuseRecording from './MuseRecording';

function MeasurePage() {
	const [btApiAvailable, setBtApiAvailable] = useState(true);
	const [btAdapterAvailable, setBtAdapterAvailable] = useState(true);
	const [connecting, setConnecting] = useState(false);
	const [connected, setConnected] = useState(false);
	const [batteryLevel, setBatteryLevel] = useState(null);
	const [controlResponses, setControlResponses] = useState(null);
	const [data, setData] = useState(null);
	const [enableAux, setEnableAux] = useState(true);
	const museRef = useRef(new MuseClient());
	const destroyRef = useRef(new Subject());

	useEffect(() => {
		if (_.isUndefined(navigator.bluetooth)) {
			setBtApiAvailable(false);
		}
		return () => {
			destroyRef.current.next();
		};
	}, []);

	const handleConnecting = async () => {
		if (!connected && !connecting) {
			setBtAdapterAvailable(true);
			setConnecting(true);
			museRef.current.enableAux = enableAux;
			museRef.current.connectionStatus.pipe(takeUntil(destroyRef.current)).subscribe(status => {
				setConnected(status);
				setData(null);
				setBatteryLevel(null);
			});
			try {
				await museRef.current.connect();
				museRef.current.controlResponses.pipe(takeUntil(destroyRef.current)).subscribe(d => setControlResponses(d));
				await museRef.current.start();
				setData(
					museRef.current.eegReadings.pipe(
						zipSamples,
						takeUntil(destroyRef.current),
						//tap(() => this.cd.detectChanges()),
						share()
					)
				);
				await museRef.current.deviceInfo();
			} catch (err) {
				console.log(err.toString());
				if (err.message === 'Bluetooth adapter not available.') {
					setBtAdapterAvailable(false);
				} else if (err.message === 'User cancelled the requestDevice() chooser.') {
					setBtAdapterAvailable(true);
				}
				setConnecting(false);
				setConnected(false);
			} finally {
				setConnecting(false);
			}
		}
	};

	const handleDisconnecting = () => {
		museRef.current.disconnect();
		setBtAdapterAvailable(true);
		setConnected(false);
		setConnecting(false);
	};

	return (
		<div className="pt-32 p-site-x flex-1 flex flex-col items-center justify-center">
			{btApiAvailable ? (
				!connected ? (
					<div className="mt-16">
						<div>
							<div className="flex flex-col">
								<div className="w-full text-center pb-12 pt-12 hidden">
									<input id="use-stun" type="checkbox" checked={enableAux} onChange={e => setEnableAux(e.target.checked)} />
									Enable Auxilary Electrode
								</div>
								<button className="button" onClick={() => handleConnecting()} disabled={connecting}>
									Connect
								</button>
							</div>
							{!btAdapterAvailable ? (
								<div className="mt-16">
									<motion.div
										className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
										initial={{ scale: 0.5, opacity: 0 }}
										animate={{ scale: 1, opacity: 1 }}
										transition={{ delay: 0, type: 'spring', stiffness: 100 }}
									>
										<div className="flex flex-col items-center gap-2">
											<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Bluetooth not found</h1>
										</div>
										<div className="mb-4">A Bluetooth adapter is necessary to run the measure.</div>
									</motion.div>
								</div>
							) : null}
							{connecting ? (
								<div className="mt-16">
									<motion.div
										className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
										initial={{ scale: 0.5, opacity: 0 }}
										animate={{ scale: 1, opacity: 1 }}
										transition={{ delay: 0, type: 'spring', stiffness: 100 }}
									>
										<div className="flex flex-col items-center gap-2">
											<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Muse2 connecting</h1>
											<div className="mb-4">Please pair your Muse 2 device</div>
										</div>
									</motion.div>
								</div>
							) : null}
						</div>
					</div>
				) : (
					<div className="mt-16 w-full">
						{data ? <MuseRecording data={data} enableAux={enableAux} destroyRef={destroyRef} /> : null}
						{data ? <MuseChart data={data} enableAux={enableAux} destroyRef={destroyRef} /> : null}
					</div>
				)
			) : (
				<div className="mt-16">
					<motion.div
						className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
						initial={{ scale: 0.5, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{ delay: 0, type: 'spring', stiffness: 100 }}
					>
						<div className="flex flex-col items-center gap-2">
							<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Bluetooth Not Available</h1>
						</div>

						<div className="mb-4">
							Web Bluetooth is available only in Chrome at the moment. In order to see the measure, please open this page in Google Chrome. <br />
							If You use Linux and Chrome, please enable your Bluetooth device. You can do it on the following link: chrome://flags/#enable-experimental-web-platform-features and
							chrome://flags/#enable-web-bluetooth-new-permissions-backend
						</div>
					</motion.div>
				</div>
			)}
		</div>
	);
}

export default MeasurePage;
