import _ from '@lodash';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import PersonalDataService from 'service/personalData/PersonalDataService';
import Loader from 'elements/Loader';

const booleanOptions = [
	{
		label: 'Yes',
		value: true
	},
	{
		label: 'No',
		value: false
	}
];

const selectCustomStyles = {
	control: styles => ({ ...styles, padding: '8px 5px 8px 5px', borderWidth: '2px', borderColor: 'rgb(229,231,235)' })
};

export default function DatasetRegistration(props) {
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [formData, setFormData] = useState(null);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setError,
		reset
	} = useForm({ mode: 'onChange', defaultValues: {} });

	useEffect(() => {
		if (props.data) reset(props.data);
	}, [props.data]);

	useEffect(() => {
		PersonalDataService.getFormData().then(resp => {
			const _formData = {};
			Object.keys(resp).map(key => {
				_formData[key] = resp[key].map(d => {
					return { value: d.id, label: d.name };
				});
			});

			setFormData(_formData);
		});
	}, []);

	const onSubmit = data => {
		setSubmitDisabled(true);
		if (props.update) {
			PersonalDataService.update(data).then(response => handleServiceResp(response));
		} else {
			PersonalDataService.save(data).then(response => handleServiceResp(response));
		}
	};

	const handleServiceResp = response => {
		if (response.successful) {
			props.onNext(response.id);
		} else {
			setSubmitDisabled(false);
			Object.keys(response.properties).forEach(key => {
				if (response.properties[key].length > 0) {
					setError(key, {
						type: 'manual',
						message: response.properties[key][0].message
					});
				}
			});
		}
	};

	return (
		<div>
			{formData !== null ? (
				<form className="card max-w-boxed flex flex-col gap-6 items-center" onSubmit={handleSubmit(onSubmit)}>
					<div className="w-full flex flex-col gap-8">
						<div className="flex flex-col gap-4">
							<BlockHeader title="Personal" subTitle="information" description="The informations below are required to log in, and continue participation in the future if needed" />

							<div className="card flex-1 grid grid-cols-1 sm:grid-cols-2 gap-4">
								<div className="flex flex-col gap-1">
									<input type="text" className="w-full" placeholder="Title" {...register('title')} />
									{errors.title && <div className="input-error-message">{errors.title.message}</div>}
								</div>

								<div className="flex flex-col gap-1">
									<input type="text" className="w-full" placeholder="First Name * " {...register('firstName')} />
									{errors.firstName && <div className="input-error-message">{errors.firstName.message}</div>}
								</div>

								<div className="flex flex-col gap-1">
									<input type="text" className="w-full" placeholder="Last Name * " {...register('lastName')} />
									{errors.lastName && <div className="input-error-message">{errors.lastName.message}</div>}
								</div>

								<div className="flex flex-col gap-1">
									<input type="text" className="w-full" placeholder="E-mail address * " {...register('email')} autoComplete="off" readOnly={props.update} />
									{errors.email && <div className="input-error-message">{errors.email.message}</div>}
								</div>

								<div className="flex flex-col gap-1">
									<input type="password" className="w-full" placeholder={props.update ? 'Password' : 'Password *'} {...register('password')} autoComplete="new-password" />
									{errors.password && <div className="input-error-message">{errors.password.message}</div>}
								</div>

								<div className="flex flex-col gap-1">
									<input type="password" className="w-full" placeholder={props.update ? 'Password again' : 'Password agan *'} {...register('passwordAgain')} autoComplete="new-password" />
									{errors.passwordAgain && <div className="input-error-message">{errors.passwordAgain.message}</div>}
								</div>
							</div>
						</div>

						<div className="flex flex-col gap-4">
							<BlockHeader title="Additional" subTitle="information" description="This information is needed to get a more accurate picture based four the software based on your characteristics" />

							<div className="card flex-1 grid grid-cols-1 sm:grid-cols-2 gap-4">
								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="race"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Race"
												inputRef={ref}
												value={value ? formData.race.filter(c => value.includes(c.value)) : null}
												onChange={val => onChange(val.map(c => c.value))}
												options={formData.race}
												isMulti
											/>
										)}
									/>
								</div>

								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="hairColor"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Hair color"
												inputRef={ref}
												value={value ? formData.hairColor.filter(c => value.includes(c.value)) : null}
												onChange={val => onChange(val.map(c => c.value))}
												options={formData.hairColor}
												isMulti
											/>
										)}
									/>
								</div>

								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="eyeColor"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Eye color"
												inputRef={ref}
												value={value ? formData.eyeColor.filter(c => value.includes(c.value)) : null}
												onChange={val => onChange(val.map(c => c.value))}
												options={formData.eyeColor}
												isMulti
											/>
										)}
									/>
								</div>

								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="faceShape"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Face shape"
												inputRef={ref}
												value={value ? formData.faceShape.filter(c => value === c.value) : null}
												onChange={val => onChange(val.value)}
												options={formData.faceShape}
											/>
										)}
									/>
								</div>

								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="glasses"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Glasses"
												inputRef={ref}
												value={value ? booleanOptions.filter(c => value === c.value) : null}
												onChange={val => onChange(val.value)}
												options={booleanOptions}
											/>
										)}
									/>
								</div>

								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="mustache"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Mustache"
												inputRef={ref}
												value={_.isBoolean(value) ? booleanOptions.filter(c => value === c.value) : null}
												onChange={val => onChange(val.value)}
												options={booleanOptions}
											/>
										)}
									/>
								</div>

								<div className="flex flex-col gap-1">
									<Controller
										control={control}
										name="beard"
										render={({ field: { onChange, value, ref } }) => (
											<Select
												styles={selectCustomStyles}
												placeholder="Beard"
												inputRef={ref}
												value={value ? booleanOptions.filter(c => value === c.value) : null}
												onChange={val => onChange(val.value)}
												options={booleanOptions}
											/>
										)}
									/>
								</div>
							</div>
						</div>
					</div>

					{!props.update ? (
						<div>
							<BlockHeader title="Terms" subTitle="and conditions" description="To continue with the registration, you must accept the terms and conditions below" />

							<div className="card flex flex-row gap-4 items-center justify-center">
								<input className="w-10 h-10" type="checkbox" id="accept" {...register('accept')} />

								<div className="flex flex-col gap-2">
									<label className="text-xs" htmlFor="accept">
										Hereby I consent to the recording and use of my data to be added to the the Human Meta-Communication Dataset. The intended purpose of the Human Meta-Communication Dataset is to train algorithms to
										detect facial expressions and gestures.
									</label>

									{errors.accept && <div className="input-error-message">{errors.accept.message}</div>}
								</div>
							</div>
						</div>
					) : null}

					<input className="button w-full" type="submit" value="Ok, send it" disabled={submitDisabled} />
				</form>
			) : (
				<Loader />
			)}
		</div>
	);
}

function BlockHeader(props) {
	return (
		<div className="flex flex-col gap-2">
			<div className="flex flex-col sm:flex-row items-start sm:items-end gap-1 sm:gap-4">
				<h1 className="leading-none">{props.title}</h1>
				<h1 className="text-2xl sm:text-4xl text-gray-300 leading-none">{props.subTitle}</h1>
			</div>
			{props.description}
		</div>
	);
}
