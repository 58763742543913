import { useEffect, useCallback, useState } from 'react';
import Webcam from 'react-webcam';

export default function CameraAuthComponent(props) {
	const [authorized, setAuthorized] = useState(false);
	const [showAuthorization, setShowAuthorization] = useState(false);

	const handleDevices = useCallback(
		mediaDevices => {
			const videoDevices = mediaDevices.filter(({ kind, deviceId }) => kind === 'videoinput' && deviceId.length > 0);
			if (videoDevices.length > 0) {
				props.onAuth();
			} else {
				setShowAuthorization(true);
			}
		},
		[setShowAuthorization]
	);

	useEffect(() => {
		navigator.mediaDevices.enumerateDevices().then(handleDevices);
	}, [handleDevices]);

	const handleStream = () => {
		setAuthorized(true);
		props.onAuth();
	};

	return (
		<div>
			{showAuthorization ? (
				<div className="max-w-boxed flex flex-col gap-6 items-center">
					{!authorized ? (
						<div className="flex flex-col gap-2 items-center text-center">
							<h2>Please authorize this webpage to access your web camera.</h2>
						</div>
					) : null}

					<div className={authorized ? 'hidden' : 'block'}>
						<img src="images/dataset/camera_authorize.png" alt="Authorize Camera" />
					</div>

					<Webcam className="hidden" audio={false} onUserMedia={() => handleStream()} />
				</div>
			) : null}
		</div>
	);
}
