import nBossConnection from 'modules/base/service/nBossConnection';
import DatasetDto from 'dto/dataset/DatasetDto';
import EntityValidationResult from 'modules/base/dto/validation/EntityValidationResult';

class DatasetService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'datasetControl';
	}

	/**
	 * @param {DatasetDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	save(dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/save`, { dto })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} id
	 * @param {DatasetDto} dto
	 * @return {Promise<EntityValidationResult>}
	 */
	update(id, dto) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/update`, { id, dto })
				.then(response => resolve(response.data !== null ? new EntityValidationResult().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @param {number} itemId
	 * @return {Promise<DatasetDto>}
	 */
	getItem(itemId) {
		return new Promise((resolve, reject) => {
			this.connection
				.post(`/${this.CONTROL}/getItem`, { itemId })
				.then(response => resolve(response.data !== null ? new DatasetDto().setup(response.data) : null))
				.catch(reject);
		});
	}

	/**
	 * @return {Promise<DatasetDto[]>}
	 */
	getItems() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getItems`)
				.then(response => resolve(response.data !== null ? response.data.map(d => new DatasetDto().setup(d)) : null))
				.catch(reject);
		});
	}
}

export default new DatasetService();
