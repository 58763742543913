import nBossConnection from 'modules/base/service/nBossConnection';
import { useRef, useCallback, useState, useEffect } from 'react';
import { useInterval } from 'react-timers-hooks';
import Webcam from 'react-webcam';
import DatasetService from 'service/dataset/DatasetService';
import { DataURIToBlob } from 'utils/Base64Utils';
import { motion } from 'framer-motion';
import { DatasetItemHeader, MediaScreen } from 'dataset/DatasetPage';

export default function DatasetItem(props) {
	const _tick = 3;

	const webcamRef = useRef(null);
	const [imgSrc, setImgSrc] = useState(null);
	const [fileKey, setFileKey] = useState(null);
	const [submitError, setSubmitError] = useState(false);
	const [step, setStep] = useState('example');
	const [tick, setTick] = useState(_tick);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (props.data) {
			setSubmitting(false);
			setStep('example');
			setFileKey(null);
			setImgSrc(null);
			const pos = props.list.indexOf(props.data);
		}
	}, [props.data]);

	useEffect(() => {
		setStep(props.submittedData ? 'submitted' : 'example');
	}, [props.submittedData]);

	useInterval(
		() => {
			setTick(tick - 1);
			if (tick === 1) capture();
		},
		step === 'capture' && tick > 0 ? 1000 : null
	);

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setImgSrc(imageSrc);
		setStep('current');
	}, [webcamRef, setImgSrc, setStep]);

	useEffect(() => {
		if (imgSrc !== null) {
			const formData = new FormData();
			formData.append('file', DataURIToBlob(imgSrc), `${props.personalDataId}-${props.data.key}.jpg`);
			nBossConnection.connection
				.post(`fileRepository/addFile`, formData)
				.then(resp => {
					setFileKey(resp.data);
				})
				.catch(e => {
					setSubmitError(true);
					setFileKey(null);
				});
		}
	}, [imgSrc]);

	const addToDataset = () => {
		if (fileKey !== null) {
			setSubmitting(true);
			const dto = {
				id: null,
				person: props.personalDataId,
				item: props.data.id,
				media: fileKey
			};
			if (props.submittedData) {
				dto.id = props.submittedData.id;
				DatasetService.update(props.submittedData.id, dto).then(resp => {
					if (resp.successful) {
						props.onSubmitted(dto);
						props.onNext();
					} else {
						setSubmitError(true);
					}
				});
			} else {
				DatasetService.save(dto).then(resp => {
					if (resp.successful) {
						dto.id = resp.id;
						props.onSubmitted(dto);
						props.onNext();
					} else {
						setSubmitError(true);
					}
				});
			}
		}
	};

	const videoConstraints = {
		facingMode: 'user'
	};

	return (
		<div className='w-full max-w-boxed flex flex-col gap-6 items-center'>

			<DatasetItemHeader heading={props.data.name} subHeading={props.data.itemType} description={props.data.description} error={submitError} />

			<motion.div className='w-full flex flex-col items-center'
				initial={{ scale: 0.5, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{ type: 'spring', stiffness: 100 }}>

				{step === 'example' && <MediaScreen
					imageChildren={props.data.media && <div className='w-full h-full bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${nBossConnection.baseUrl}fileRepository/getFile?key=${props.data.media})` }} />}
					buttonChildren={<>
						<button className='flex-1 button' onClick={() => { setTick(_tick); setStep('capture'); }}>I am prepared, let's take the picture</button>
						<button className='button' onClick={() => props.onNext()}>{props.isLast ? 'Next and Finish' : 'Next'}</button>
					</>}
				/>}

				{step === 'capture' && <MediaScreen
					imageChildren={<Webcam className='w-full' audio={false} ref={webcamRef} videoConstraints={videoConstraints} screenshotFormat='image/jpeg' />}
					buttonChildren={<motion.h1
						className='text-center'
						initial={{ scale: 0.5, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}>{tick > 0 ? tick : ''}</motion.h1>}
				/>}

				{step === 'current' && <MediaScreen
					imageChildren={<div className='w-full h-full bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${nBossConnection.baseUrl}fileRepository/getFile?key=${fileKey})` }} />}
					buttonChildren={<>
						<button className='flex-1 button' onClick={() => addToDataset()} disabled={submitting}><span className='font-light text-xs'>I like the photo.</span>Add it to the dataset</button>
						<button className='flex-1 button' onClick={() => { setTick(_tick); setStep('capture'); }} disabled={submitting}><span className='font-light text-xs'>I don't really like this photo,</span>I would like to redo it</button>
					</>}
				/>}

				{(step === 'submitted' && props.submittedData) && <MediaScreen
					imageChildren={props.submittedData.media && <div className='w-full h-full bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${nBossConnection.baseUrl}fileRepository/getFile?key=${props.submittedData.media})` }} />}
					buttonChildren={<>
						<button className='flex-1 button' onClick={() => setStep('example')}><span className='font-light text-xs'>I don't really like this photo,</span>I would like to redo it</button>
						<button className='button' onClick={() => props.onNext()}>{props.isLast ? 'Next and Finish' : 'Next'}</button>
					</>}
				/>}

			</motion.div>

		</div>
	);
}