import { Link } from 'react-router-dom';

export default function Demos(props) {
	return (
		<div className={'py-24 p-site-x flex flex-col gap-16 items-center justify-center bg-white ' + (props.className || '')} id='demos'>

			<div className='flex flex-col items-center gap-4 text-center'>
				<h1>Demos</h1>
				<div className='w-16 sm:w-32 h-2 bg-sky-700' />
				<div className='max-w-3xl text-sm'>We have prepared three online demos for you, so that you can have a look at our AI based technology.</div>
			</div>

			<div className='flex flex-col sm:flex-row gap-8 max-w-7xl'>

				<DemoItem
					className='flex-1'
					title='Facial expression'
					description='A web camera is necessary to run this demo. The camera should point at the face of the user. Please point the camera to your face. The goal of this demo is to detect basic emotions, such as anger , disgust, fear, happiness, sadness, surprise and neutral / no emotion.'
					to='/facial-expression' />

				<DemoItem
					className='sm:w-1/2'
					title='Gestures'
					description='A web camera is necessary to run this demo. The camera should point at the upper body of the user. Please point your camera to your upper body. The goal of this demo is to detect basic gestures, such as pointing, hands crossing, straight or bent posture, over gesticulating, keeping hands at waist level, touching hair and raising hands to face, thumbs up/down, head tilt, touching face, touching nose, hands visibility, palm visibility, folding fingers into fist, keeping hands at waist level, keeping hands near the body while standing, keeping hands behind the body while standing or in pockets, establishing eye contact with the camera, head nodding, smiling, making a grimace or angry face and blinking.'
					to='/gestures' />

				<DemoItem
					className='flex-1'
					title='Muse'
					description='A Muse2 device should be placed on your head properly. The goal of this demo is to extract basic features, such as whether you are relaxed, concentrating or neutral. You can start Muse2 streaming by clicking the start button below.'
					to='/muse' />

			</div>

		</div>
	);
}

function DemoItem(props) {
	return (
		<div className={'flex flex-col gap-4 border rounded-xl p-10 justify-between ' + (props.className || '')}>

			<h2 className='font-extrabold'>{props.title}</h2>
			<h3>{props.description}</h3>

			<Link to={props.to} className='button'>Try it</Link>

		</div>
	);
}