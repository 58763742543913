import IdentifiableDto from '../IdentifiableDto';
import SimplePropertyValidation from './SimplePropertyValidation';

class EntityValidationResult extends IdentifiableDto {
	/**
	 * @module EntityValidationResult
	 */
	constructor() {
		super();
		/** @type {boolean} */
		this.successful = null;
		/** @type {Map<string,Set<SimplePropertyValidation>>} */
		this.properties = null;
	}

	/**
	 * @typedef {Object} _EntityValidationResultProps
	 * @property {boolean} successful
	 * @property {Map<string, Set<SimplePropertyValidation>>} properties
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _EntityValidationResultProps} EntityValidationResultProps
	 */
	/**
	 *
	 * @param {EntityValidationResultProps} props
	 * @returns {EntityValidationResult}
	 */
	setup(props) {
		super.setup(props);
		this.successful = props.successful;
		this.properties = props.properties;
		return this;
	}
}

export default EntityValidationResult;
