import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import * as Icons from 'react-icons/fa';

export default function DatasetItemSelector(props) {
	const [open, setOpen] = useState(false);

	const [emotionItemList, setEmotionItemList] = useState([]);
	const [gestureItemList, setGestureItemList] = useState([]);

	useEffect(() => {
		if (props.itemList !== null) {
			setEmotionItemList(props.itemList.filter(d => d.itemType === 'EMOTION' && d.key !== 'intro'));
			setGestureItemList(props.itemList.filter(d => d.itemType === 'GESTURE' && d.key !== 'intro'));
		}
	}, [props.itemList]);

	return (
		<motion.div
			className='fixed w-80 h-screen top-0 right-0 z-50'
			transition={{ ease: open ? 'backIn' : 'backOut' }}
			initial={open ? 'visible' : 'hidden'}
			animate={open ? 'visible' : 'hidden'}
			variants={{ visible: { x: 0 }, hidden: { x: '100%' } }}>

			<div className='absolute top-0 left-0 w-0 h-full flex items-center justify-center'>
				<div className='relative w-full'>
					<div className='absolute right-0 flex flex-row items-center justify-center'>
						<OpenButton className='absolute right-0' onClick={() => setOpen(!open)} open={open} />
					</div>
					{/* <OpenButton className='absolute right-0' onClick={() => setOpen(!open)} open={open} /> */}
				</div>
			</div>

			<div className='flex flex-col gap-4 p-8 w-80 h-screen bg-sky-700 shadow-xl overflow-y-scroll'>

				<Block title='Emotion'>
					{emotionItemList.map(item => (<Item key={item.key} step={props.step} currentItem={props.currentItem} peronsalItemList={props.peronsalItemList} item={item} onClick={() => { props.onItemClick('EMOTION', item.position); setOpen(false) }} />))}
				</Block>

				<Block title='Gesture'>
					{gestureItemList.map(item => (<Item key={item.key} step={props.step} currentItem={props.currentItem} peronsalItemList={props.peronsalItemList} item={item} onClick={() => { props.onItemClick('GESTURE', item.position); setOpen(false) }} />))}
				</Block>

			</div>

		</motion.div>
	);
}

function Block(props) {
	return (
		<div className='flex flex-col gap-2'>
			<div>
				<div className='uppercase text-3xl font-extrabold text-white'>{props.title}</div>
			</div>
			<div className='flex flex-col gap-2'>{props.children}</div>
		</div>
	);
}

function Item(props) {
	const selected = props.step === 'item' && props.currentItem && props.currentItem.id === props.item.id;
	const finished = props.peronsalItemList.findIndex(d => d.item === props.item.id) > -1;

	return (
		<button className={'flex flex-row justify-between gap-4 items-center rounded text-xs font-extrabold px-4 py-3 text-left text-white transition ' + (selected ? 'bg-gray-900 hover:bg-opacity-50' : 'bg-white bg-opacity-20 hover:bg-opacity-30')} onClick={() => props.onClick()}>
			<div className='flex-1'>{props.item.name}</div>
			{finished ? <Icons.FaCheck /> : <Icons.FaMinus />}
		</button>
	);
}

function OpenButton(props) {
	return (
		<button className={'min-h-16 min-w-16 p-4 shadow-xl rounded-l transition text-white ' + (props.open ? 'bg-gray-900 hover:bg-gray-100 hover:text-gray-900 ' : 'bg-sky-700 hover:bg-gray-900 ') + (props.className || '')} onClick={() => props.onClick()}>

			<div className='flex flex-col gap-1 items-center justify-center'>
				<div className='text-2xl'>{props.open ? <Icons.FaChevronRight /> : <Icons.FaCheckDouble />}</div>
				<div>
					<div className='font-light text-sm'>{props.open ? 'hide' : 'show'}</div>
					<div className='leading-none text-xs font-extrabold uppercase'>progress</div>
				</div>
			</div>

		</button>
	);
}