import { Link } from 'react-router-dom';

export default function Founding(props) {
	return (
		<div className={'py-24 p-site-x flex flex-col gap-16 items-center justify-center bg-white border-t shadow-xl ' + (props.className || '')} id='demos'>


			<div className='w-full max-w-[820px] flex flex-col  gap-8 '>
				<img className={'transition-all '} src='images/nkfia.png' alt='NKFIA' />
				<div className='text-center'>The project number 2019-2.1.1-EUREKA-2020-00018 was realized with the support provided by the Ministry of Innovation and Technology from the National Research Development and Innovation Fund, financed by the 2019-2.1.1-EUREKA tender program.</div>

			</div>

		</div>
	);
}