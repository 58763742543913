import { DatasetItemHeader, MediaScreen } from 'dataset/DatasetPage';

function DatasetDownloadNotAvailable(props) {
	return (
		<div className="w-full max-w-boxed flex flex-col gap-6 items-center justify-center flex-1">
			<DatasetItemHeader heading={'You recording is not completed'} subHeading={''} description={'Please complete your contribution before downloading the dataset.'} />
			<MediaScreen
				imageContainerClassName="aspect-[4/3]"
				imageChildren={<img className="w-full" src="images/dataset/thank-you-for-your-cooperation.jpg" alt="Thank you for your cooperation!" />}
				buttonChildren={
					<>
						<button className="flex-1 button alt" onClick={() => props.onBackToRecording()}>
							Back to recording
						</button>
					</>
				}
			/>
		</div>
	);
}

export default DatasetDownloadNotAvailable;
