import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import DatasetRegistration from './DatasetRegistration';
import PersonalDataService from 'service/personalData/PersonalDataService';
import Loader from 'elements/Loader';

function DatasetModifyProfile(props) {
	const [view, setView] = useState('loading');
	const [data, setData] = useState(null);

	useEffect(() => {
		PersonalDataService.getData().then(resp => {
			setData(resp);
			setView('update');
		});
	}, []);

	if (view === 'loading') return <Loader />;
	if (view === 'update')
		return (
			<div className="w-full flex flex-col items-center justify-center flex-1">
				<DatasetRegistration onNext={() => setView('updated')} update data={data} />
			</div>
		);
	if (view === 'updated')
		return (
			<motion.div
				className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
				initial={{ scale: 0.5, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{ delay: 0, type: 'spring', stiffness: 100 }}
			>
				<div className="flex flex-col items-center gap-2">
					<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Profile successfully saved</h1>
				</div>

				<div className="mb-4">
					Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
					make a type specimen book.
				</div>

				<motion.div
					className="absolute w-full h-0 bg-red-400 bottom-0 left-0 flex items-center justify-center"
					initial={{ scale: 0.5, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{ delay: 0.4, type: 'spring', stiffness: 100 }}
				>
					<button onClick={() => props.onNext()} className="button bg-gray-900 hover:bg-white text-white hover:text-gray-900 shadow-xl">
						Back to dataset
					</button>
				</motion.div>
			</motion.div>
		);
}

export default DatasetModifyProfile;
