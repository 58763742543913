import NamedDto from './NamedDto';

export class KeyedAndNamedDto extends NamedDto {
	/**
	 * @module KeyedAndNamedDto
	 */
	constructor() {
		super();
		/** @type {string} */
		this.key = null;
	}

	/**
	 * @typedef {Object} _KeyedAndNamedDtoProps
	 * @property {string} key
	 *
	 * @typedef {import("./NamedDto").NamedDtoProps & _KeyedAndNamedDtoProps} KeyedAndNamedDtoProps
	 */
	/**
	 *
	 * @param {KeyedAndNamedDtoProps} props
	 * @returns {KeyedAndNamedDto}
	 */
	setup(props) {
		super.setup(props);
		this.key = props.key;
		return this;
	}
}

export default KeyedAndNamedDto;
