import { useEffect, useCallback, useState, useRef } from 'react';
import Webcam from 'react-webcam';

export default function WebsocketImageTest() {
	const webcamRef = useRef(null);
	const videoStreamRef = useRef(null);
	const websocketRef = useRef(null);
	const [capturing, setCapturing] = useState(false);
	const requestRef = useRef();

	const videoConstraints = {
		facingMode: 'user'
	};

	const capture = time => {
		webcamRef.current.getCanvas().toBlob(
			d => {
				websocketRef.current.send({ time: time, size: d.size });
				websocketRef.current.send(d);
			},
			'image/jpeg',
			0.8
		);
		requestRef.current = requestAnimationFrame(capture);
	};

	useEffect(() => {
		websocketRef.current = new WebSocket('wss://creometa.dev.office.creo.hu/video-image');
		return () => {
			if (requestRef.current) cancelAnimationFrame(requestRef.current);
			websocketRef.current.close();
		};
	}, []);

	const handleStartCaptureClick = useCallback(() => {
		setCapturing(true);
		capture();
	}, [webcamRef, setCapturing]);

	const handleStopCaptureClick = useCallback(() => {
		setCapturing(false);
		if (requestRef.current) cancelAnimationFrame(requestRef.current);
	}, [webcamRef, setCapturing]);

	return (
		<div className="py-16 p-site-x min-h-screen flex flex-col items-center justify-center bg-sky-700">
			<div className="max-w-boxed flex flex-col gap-6 text-white text-center">
				<div className="flex flex-row">
					<Webcam className="h-full mr-20" audio={false} ref={webcamRef} width={338} height={600} videoConstraints={videoConstraints} />
					<video ref={videoStreamRef} className="rounded-xl overflow-hidden" width="338" height="600" autoPlay playsInline></video>
				</div>
				<div>
					{capturing ? (
						<button className="button" onClick={handleStopCaptureClick}>
							Stop stream
						</button>
					) : (
						<button className="button" onClick={handleStartCaptureClick}>
							Start stream
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
