import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import Hero from '../components/meta/Hero';
import Contact from '../components/creo/Contact';
// import Portfolio from '../components/creo/Portfolio';
import Founding from '../components/meta/Founding';
import Dataset from '../components/meta/Dataset';
import Demos from 'components/meta/Demos';

export default function StartPage() {
	const { pathname } = useLocation();
	useEffect(() => {
		updatePosition();
	}, [pathname]);

	const updatePosition = () => {
		if (pathname === '/demos') {
			scroller.scrollTo('demos', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});
		} else if (pathname === '/contact') {
			scroller.scrollTo('contact', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});
		} else if (pathname === '/dataset') {
			scroller.scrollTo('dataset', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});
		} else {
			scroller.scrollTo('startPage', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});
		}
	};

	return (
		<div className='flex flex-col' id='startPage'>
			<Demos className='order-2 z-20 shadow-xl' />
			<Dataset className='order-3 z-10' />
			<Contact className='order-4 z-10' />
			<Founding className='order-5' />
			<Hero className='order-1' />		
		</div>
	);
}
