import IdentifiableDto from 'modules/base/dto/IdentifiableDto';

export class DatasetDto extends IdentifiableDto {
	constructor() {
		super();
		/** @type {number} */
		this.person = null;
		/** @type {number} */
		this.item = null;
		/** @type {string} */
		this.media = null;
	}

	/**
	 * @typedef {Object} _DatasetDto
	 * @property {number} person
	 * @property {number} item
	 * @property {string} media
	 * @typedef {import("../IdentifiableDto").IdentifiableDtoProps & _DatasetDto} DatasetDto
	 */
	/**
	 *
	 * @param {DatasetDto} props
	 * @returns {DatasetDto}
	 */
	setup(props) {
		super.setup(props);
		this.person = props.person;
		this.item = props.item;
		this.media = props.media;
		return this;
	}
}

export default DatasetDto;
