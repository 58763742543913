import { useEffect, useState } from 'react';
import { useInterval } from 'react-timers-hooks';
import { motion } from 'framer-motion';
import Webcam from 'react-webcam';

export default function DatasetCameraAuthorize(props) {
	const [step, setStep] = useState('loading');

	useEffect(() => {
		if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
			setStep('notSupported');
		} else {
			checkCameraAvailable();
		}
	});

	useInterval(
		() => {
			checkCameraAvailable();
		},
		step === 'notConnected' ? 2000 : null
	);

	const handleStream = () => {
		props.onNext();
	};

	const checkCameraAvailable = () => {
		navigator.mediaDevices.enumerateDevices().then(devices => {
			if (devices) {
				let videoDeviceFound = false;
				devices.forEach(dev => {
					if (dev.kind === 'videoinput') videoDeviceFound = true;
				});
				if (videoDeviceFound) {
					setStep('authorize');
				} else {
					setStep('notConnected');
				}
			} else {
				setStep('notConnected');
			}
		});
	};

	return (
		<div className='max-w-boxed flex flex-col gap-6 items-center'>

			{step === 'notSupported' && <Message message='Your browser does not support web camera.' />}
			{step === 'notConnected' && <Message message='The web camera does not connected or turned off.' />}
			{step === 'authorize' && <Message message='Please authorize this webpage to access your web camera.' imageChildren={<>
				<div className='uppercase font-extrabold'>The authorizing prompt should look something like this:</div>
				<img className='w-[348px]' src='images/dataset/camera_authorize.png' alt='Authorize Camera' />
				<div className='overflow-hidden hidden'><Webcam audio={false} onUserMedia={() => handleStream()} /></div></>}
			/>}

			{/* {step === 'notSupported' ? (
				<div className='flex flex-col gap-2 items-center text-center'>
					<h1>A web camera is necessary to collect videos.</h1>
					<h2>Your browser does not support web camera.</h2>
				</div>
			) : null}

			{step === 'notConnected' ? (
				<div className='flex flex-col gap-2 items-center text-center'>
					<h1>A web camera is necessary to collect videos.</h1>
					<h2>The web camera does not connected or turned off.</h2>
				</div>
			) : null} */}

			{/* {step === 'authorize' ? (
				<div className='flex flex-col gap-2 items-center text-center'>
					<h1>A web camera is necessary to collect videos.</h1>
					<h2>Please authorize this webpage to access your web camera.</h2>

					<img src='images/dataset/camera_authorize.png' alt='Authorize Camera' className='mt-20' />
					<div className='overflow-hidden hidden'>
						<Webcam audio={false} onUserMedia={() => handleStream()} />
					</div>
				</div>
			) : null} */}

		</div>
	);
}

function Message(props) {
	return (
		<motion.div
			className='flex flex-col gap-8 items-center text-center'
			initial={{ scale: 0.5, opacity: 0 }}
			animate={{ scale: 1, opacity: 1 }}
			transition={{ delay: 0, type: 'spring', stiffness: 100 }}>

			<div className='flex flex-col items-center gap-2'>

				<h1 className='text-2xl sm:text-6xl font-extrabold uppercase'>A web camera</h1>
				<h1 className='text-7xl'>is necessary</h1>
				<h1 className='text-2xl sm:text-3xl font-extrabold uppercase'>to collect images and videos</h1>

			</div>

			<div className='w-16 sm:w-64 h-2 bg-sky-700' />

			<div className='uppercasee text-2xl font-light'>Please authorize this webpage to access your web camera</div>


			{props.imageChildren && <div className='flex flex-col items-center gap-4'>{props.imageChildren}</div>}

		</motion.div>
	);
}