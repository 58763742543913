import nBossConnection from 'modules/base/service/nBossConnection';
import { getSessionString } from 'modules/base/utils/GenericUtils';
import { useRef, useCallback, useState, useEffect } from 'react';
import { useInterval } from 'react-timers-hooks';
import Webcam from 'react-webcam';
import DatasetService from 'service/dataset/DatasetService';
import MyFileRepositoryService from 'service/MyFileRepositoryService';
import { motion } from 'framer-motion';
import { DatasetItemHeader, MediaScreen } from 'dataset/DatasetPage';

export default function DatasetItem(props) {
	const webcamRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const [capturing, setCapturing] = useState(false);
	const [recordedChunk, setRecordedChunk] = useState(null);
	const [fileKey, setFileKey] = useState(null);
	const [clientKey, setClientKey] = useState(null);
	const [chunkOrder, setChunkOrder] = useState(0);
	const [submitError, setSubmitError] = useState(false);
	const [step, setStep] = useState('example');
	const [tick, setTick] = useState(15);
	const [preTick, setPreTick] = useState(3);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (props.data) {
			setSubmitting(false);
			setFileKey(null);
			setClientKey(null);
			setChunkOrder(0);
		}
	}, [props.data]);

	useEffect(() => {
		setStep(props.submittedData ? 'submitted' : 'example');
	}, [props.submittedData]);

	useEffect(() => {
		if (recordedChunk !== null && recordedChunk.size > 0) {
			const formData = new FormData();
			formData.append('file', recordedChunk, `${props.personalDataId}-${props.data.key}.webm`);
			formData.append('clientKey', clientKey);
			formData.append('order', chunkOrder);
			setChunkOrder(chunkOrder + 1);
			nBossConnection.connection
				.post(`fileRepository/addFileChunk`, formData)
				.then(() => {
					if (!capturing) {
						MyFileRepositoryService.closeFileChunk(clientKey).then(resp => {
							if (resp !== null && resp.length > 0) {
								setClientKey(null);
								setChunkOrder(0);
								setFileKey(resp);
								setStep('current');
							} else {
								setSubmitError(true);
								setClientKey(null);
								setChunkOrder(0);
							}
						});
					}
				})
				.catch(e => {
					mediaRecorderRef.current.stop();
					setSubmitError(true);
					setCapturing(false);
					setFileKey(null);
					setClientKey(null);
					setChunkOrder(0);
				});
		}
	}, [recordedChunk]);

	const addToDataset = () => {
		if (fileKey !== null) {
			setSubmitting(true);
			const dto = {
				id: null,
				person: props.personalDataId,
				item: props.data.id,
				media: fileKey
			};
			if (props.submittedData) {
				dto.id = props.submittedData.id;
				DatasetService.update(props.submittedData.id, dto).then(resp => {
					if (resp.successful) {
						props.onSubmitted(dto);
						props.onNext();
					} else {
						setSubmitError(true);
					}
				});
			} else {
				DatasetService.save(dto).then(resp => {
					if (resp.successful) {
						dto.id = resp.id;
						props.onSubmitted(dto);
						props.onNext();
					} else {
						setSubmitError(true);
					}
				});
			}
		}
	};

	useInterval(
		() => {
			setPreTick(preTick - 1);
		},
		step === 'capture' && preTick > 0 ? 1000 : null
	);
	useEffect(() => {
		if (preTick === 0) handleStartCaptureClick();
	}, [preTick]);

	useInterval(
		() => {
			setTick(tick - 1);
			if (tick === 1) handleStopCaptureClick();
		},
		step === 'capture' && preTick === 0 && tick > 0 ? 1000 : null
	);

	const handleStartCaptureClick = useCallback(() => {
		setSubmitError(false);
		setClientKey(getSessionString());
		setCapturing(true);
		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
			mimeType: 'video/webm'
		});
		mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
		mediaRecorderRef.current.start(5000);
	}, [step, webcamRef, setCapturing, mediaRecorderRef]);

	const handleStopCaptureClick = useCallback(() => {
		mediaRecorderRef.current.stop();
		setCapturing(false);
		setStep('current');
	}, [mediaRecorderRef, webcamRef, setCapturing, setStep]);

	const handleDataAvailable = useCallback(
		({ data }) => {
			setRecordedChunk(prev => data);
		},
		[setRecordedChunk]
	);

	const videoConstraints = {
		facingMode: 'user'
	};

	return (
		<div className="w-full max-w-boxed flex flex-col gap-6 items-center">
			<DatasetItemHeader heading={props.data.name} subHeading={props.data.itemType} description={props.data.description} error={submitError} />

			<motion.div className="w-full flex flex-col items-center" initial={{ scale: 0.5, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ type: 'spring', stiffness: 100 }}>
				{step === 'example' && (
					<MediaScreen
						imageChildren={
							props.data.media && (
								<video className="w-full h-full" controls key={props.data.media}>
									<source src={`${nBossConnection.baseUrl}fileRepository/getFile?key=${props.data.media}`} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							)
						}
						buttonChildren={
							<>
								<button
									className="flex-1 button"
									onClick={() => {
										setPreTick(3);
										setTick(15);
										setStep('capture');
									}}
								>
									I am prepared, let's take the video
								</button>
								<button className="button" onClick={() => props.onNext()}>
									{props.isLast ? 'Next and Finish' : 'Next'}
								</button>
							</>
						}
					/>
				)}

				{step === 'capture' && (
					<MediaScreen
						imageChildren={<Webcam className="w-full" audio={false} ref={webcamRef} videoConstraints={videoConstraints} />}
						buttonChildren={
							preTick > 0 ? (
								<motion.h1 className="text-center" initial={{ scale: 0.5, opacity: 0 }} animate={{ scale: 1, opacity: 1 }}>
									{preTick}
								</motion.h1>
							) : (
								tick && (
									<motion.div className="w-full flex flex-row" initial={{ scale: 0.5, opacity: 0 }} animate={{ scale: 1, opacity: 1 }}>
										<h1 className="flex-1">Recording ...</h1>
										<h1>{tick}</h1>
									</motion.div>
								)
							)
						}
					/>
				)}

				{step === 'current' && fileKey !== null && (
					<MediaScreen
						imageChildren={
							<video className="w-full h-full" controls key={fileKey}>
								<source src={`${nBossConnection.baseUrl}fileRepository/getFile?key=${fileKey}`} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						}
						buttonChildren={
							<>
								<button className="flex-1 button" onClick={() => addToDataset()} disabled={submitting}>
									<span className="font-light text-xs">I like this video,</span>Add it to the dataset
								</button>
								<button
									className="flex-1 button"
									onClick={() => {
										setPreTick(3);
										setTick(15);
										setStep('capture');
									}}
									disabled={submitting}
								>
									<span className="font-light text-xs">I don't really like this video,</span>I would like to redo it
								</button>
							</>
						}
					/>
				)}

				{step === 'submitted' && props.submittedData && (
					<MediaScreen
						imageChildren={
							<video className="w-full h-full" controls key={props.submittedData.media}>
								<source src={`${nBossConnection.baseUrl}fileRepository/getFile?key=${props.submittedData.media}`} type="video/mp4" />
								Your browser does not support the video tag.
							</video>
						}
						buttonChildren={
							<>
								<button className="flex-1 button" onClick={() => setStep('example')}>
									<span className="font-light text-xs">I don't really like this video,</span>I would like to redo it
								</button>
								<button className="flex-1 button" onClick={() => props.onNext()}>
									{props.isLast ? 'Next and Finish' : 'Next'}
								</button>
							</>
						}
					/>
				)}
			</motion.div>
		</div>
	);
}
