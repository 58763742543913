import _ from '@lodash';
import { useState, useRef, useEffect } from 'react';
import { MuseClient, zipSamples } from 'muse-js';
import { motion } from 'framer-motion';
import { map, share, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import MuseChart from './MuseChart';
import MuseWS from './MuseWS';

const MUSE_SERVICE = 0xfe8d;

function MusePage() {
	const [btApiAvailable, setBtApiAvailable] = useState(true);
	const [btAdapterAvailable, setBtAdapterAvailable] = useState(true);
	const [connecting, setConnecting] = useState(false);
	const [connected, setConnected] = useState(false);
	const [batteryLevel, setBatteryLevel] = useState(null);
	const [controlResponses, setControlResponses] = useState(null);
	const [data, setData] = useState(null);
	const [accelerometerData, setAccelerometerData] = useState(null);
	const [enableAux, setEnableAux] = useState(true);
	const [eegMessage, setEegMessage] = useState(null);
	const museRef = useRef(new MuseClient());
	const destroyRef = useRef(new Subject());

	useEffect(() => {
		if (_.isUndefined(navigator.bluetooth)) {
			setBtApiAvailable(false);
		}
		/* else {
			
			setData(
			new Observable(subscriber => {
				let index = 0;
				//const diff = Math.floor(Math.random() * 1000) - 500;
				setInterval(() => {
					//subscriber.next({ data: [490.72265625 + diff, 757.32421875 + diff, -514.16015625 + diff, -980.46875 + diff, null], index, timestamp: Date.now() });
					subscriber.next({
						data: [Math.floor(Math.random() * 1000) - 500, Math.floor(Math.random() * 1000) - 500, Math.floor(Math.random() * 1000) - 500, Math.floor(Math.random() * 1000) - 500, null],
						index,
						timestamp: Date.now()
					});
					index = index + 1;
				}, 250);
			})
		);
		}*/
		return () => {
			destroyRef.current.next();
		};
	}, []);

	const handleConnecting = async () => {
		if (!connected && !connecting) {
			setBtAdapterAvailable(true);
			setConnecting(true);
			museRef.current.enableAux = enableAux;
			museRef.current.connectionStatus.pipe(takeUntil(destroyRef.current)).subscribe(status => {
				setConnected(status);
				setData(null);
				setAccelerometerData(null);
				setBatteryLevel(null);
			});
			try {
				await museRef.current.connect();
				museRef.current.controlResponses.pipe(takeUntil(destroyRef.current)).subscribe(d => setControlResponses(d));
				await museRef.current.start();
				setData(
					museRef.current.eegReadings.pipe(
						zipSamples,
						takeUntil(destroyRef.current),
						//tap(() => this.cd.detectChanges()),
						share()
					)
				);
				museRef.current.telemetryData
					.pipe(
						takeUntil(destroyRef.current),
						map(t => t.batteryLevel)
					)
					.subscribe(d => setBatteryLevel(d));
				setAccelerometerData(
					museRef.current.accelerometerData.pipe(
						takeUntil(destroyRef.current) /*,
						map(reading => reading.samples[reading.samples.length - 1])*/
					)
				);
				await museRef.current.deviceInfo();
			} catch (err) {
				console.log(err.toString());
				if (err.message === 'Bluetooth adapter not available.') {
					setBtAdapterAvailable(false);
				} else if (err.message === 'User cancelled the requestDevice() chooser.') {
					setBtAdapterAvailable(true);
				}
				setConnecting(false);
				setConnected(false);
			} finally {
				setConnecting(false);
			}
		}
	};

	const handleDisconnecting = () => {
		museRef.current.disconnect();
		setBtAdapterAvailable(true);
		setConnected(false);
		setConnecting(false);
	};

	return (
		<div className="pt-32 p-site-x flex-1 flex flex-col items-center justify-center">
			{!connected ? (
				<motion.div
					className="relative card max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-sky"
					initial={{ scale: 0.5, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{ delay: 0, type: 'spring', stiffness: 100 }}
				>
					<div className="flex flex-col items-center gap-2">
						<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">This demo is based on the Muse 2</h1>
					</div>

					<div className="mb-4">
						<img src="/images/muse/muse-2-product-page-3.webp" />
					</div>

					<motion.div
						className="absolute w-full h-0 bg-red-400 bottom-0 left-0 flex items-center justify-center"
						initial={{ scale: 0.5, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{ delay: 0.4, type: 'spring', stiffness: 100 }}
					>
						<a href="https://choosemuse.com/muse-2/" target="_blank" className="button bg-gray-900 hover:bg-white text-white hover:text-gray-900 shadow-xl">
							Muse 2 website
						</a>
					</motion.div>
				</motion.div>
			) : null}

			{btApiAvailable ? (
				!connected ? (
					<div className="mt-16">
						<div>
							<div className="flex flex-col">
								<div className="w-full text-center pb-12 pt-12 hidden">
									<input id="use-stun" type="checkbox" checked={enableAux} onChange={e => setEnableAux(e.target.checked)} />
									Enable Auxilary Electrode
								</div>
								<button className="button" onClick={() => handleConnecting()} disabled={connecting}>
									Connect
								</button>
							</div>
							{!btAdapterAvailable ? (
								<div className="mt-16">
									<motion.div
										className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
										initial={{ scale: 0.5, opacity: 0 }}
										animate={{ scale: 1, opacity: 1 }}
										transition={{ delay: 0, type: 'spring', stiffness: 100 }}
									>
										<div className="flex flex-col items-center gap-2">
											<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Bluetooth not found</h1>
										</div>
										<div className="mb-4">A Bluetooth adapter is necessary to run this demo.</div>
									</motion.div>
								</div>
							) : null}
							{connecting ? (
								<div className="mt-16">
									<motion.div
										className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
										initial={{ scale: 0.5, opacity: 0 }}
										animate={{ scale: 1, opacity: 1 }}
										transition={{ delay: 0, type: 'spring', stiffness: 100 }}
									>
										<div className="flex flex-col items-center gap-2">
											<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Muse2 connecting</h1>
											<div className="mb-4">Please pair your Muse 2 device</div>
										</div>
									</motion.div>
								</div>
							) : null}
						</div>
					</div>
				) : (
					<div className="mt-16 w-full">
						<div className="flex items-center justify-center">
							<div>
								<p>Battery level: {batteryLevel} %</p>
								{controlResponses ? (
									<div>
										<p>Name: {controlResponses.hn}</p>
										<p>Firmware version: {controlResponses.fw}</p>
										<p>Hardware version: {controlResponses.hw}</p>
									</div>
								) : null}
								{eegMessage !== null ? (
									<div>
										<p>Class probability: {eegMessage.Class_probability}</p>
										<p>Class: {eegMessage.Class}</p>
										<p>Counter:</p>
										<p className="pl-8">Relaxed: {eegMessage.Counter.Relaxed}</p>
										<p className="pl-8">Concentrated: {eegMessage.Counter.Concentrated}</p>
										<p className="pl-8">Neutral: {eegMessage.Counter.Neutral}</p>
										<p>Frequency:</p>
										<p className="pl-8">Relaxed: {eegMessage.Frequency.Relaxed}</p>
										<p className="pl-8">Concentrated: {eegMessage.Frequency.Concentrated}</p>
										<p className="pl-8">Neutral: {eegMessage.Frequency.Neutral}</p>
									</div>
								) : null}
							</div>
						</div>
						{connected && data ? <MuseChart data={data} enableAux={enableAux} destroyRef={destroyRef} /> : null}
						<div className="flex items-center justify-center">
							<button className="button" onClick={() => handleDisconnecting()}>
								Disconnect
							</button>
						</div>
					</div>
				)
			) : (
				<div className="mt-16">
					<motion.div
						className="relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white"
						initial={{ scale: 0.5, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{ delay: 0, type: 'spring', stiffness: 100 }}
					>
						<div className="flex flex-col items-center gap-2">
							<h1 className="text-2xl sm:text-4xl font-extrabold uppercase">Bluetooth Not Available</h1>
						</div>

						<div className="mb-4">
							Web Bluetooth is available only in Chrome at the moment. In order to see this demo, please open this page in Google Chrome. <br />
							If You use Linux and Chrome, please enable your Bluetooth device. You can do it on the following link: chrome://flags/#enable-experimental-web-platform-features and
							chrome://flags/#enable-web-bluetooth-new-permissions-backend
						</div>
					</motion.div>
				</div>
			)}
			{connected && data ? <MuseWS data={data} onEegMessage={message => setEegMessage(message)} /> : null}
		</div>
	);
}

export default MusePage;
