import * as Icons from 'react-icons/fa';

import { IconLink } from 'components/creo/Common';

export default function Footer() {
	return (
		<div className='bg-white drop-shadow-xl rounded-xl m-4 sm:m-6 p-6 flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center z-40'>

			<div className='flex flex-col sm:flex-row gap-2 sm:gap-4 items-center'>
				<img src='images/creo-dots.svg' alt='Creo Group' className='w-14' />
				<div>creo.hu Kft. © 2016-{new Date().getFullYear()}</div>
			</div>

			<div className='flex flex-col sm:flex-row gap-2 sm:gap-6'>
				<IconLink icon={<Icons.FaGlobeAfrica />} value='www.creo.hu' href='https://www.creo.hu/' />
				<IconLink icon={<Icons.FaEnvelope />} value='info@creo.hu' href='mailto:info@creo.hu' />
				<IconLink icon={<Icons.FaMobileAlt />} value='+36 1 338 1739' href='tel:+3613381739' />
			</div>

		</div>
	);
}