import CameraAuthComponent from 'components/meta/CameraAuthComponent';
import { useState, useRef } from 'react';
import { DatasetItemHeader } from 'dataset/DatasetPage';
import Webcam from 'react-webcam';

function FacialExpressionPage() {
	const [cameraAuth, setCameraAuth] = useState(false);
	const [streaming, setStreaming] = useState(false);
	const videoStreamRef = useRef(null);

	const handleStartStream = () => {
		setStreaming(true);
	};
	const handleStopStream = () => {
		setStreaming(false);
	};

	const videoConstraints = {
		facingMode: 'user'
	};

	return (
		<div className='pt-32 p-site-x flex-1 flex flex-col items-center justify-center'>

			{!cameraAuth && <CameraAuthComponent onAuth={() => setCameraAuth(true)} />}

			{cameraAuth && (<div className='w-full max-w-boxed flex flex-col gap-6 items-center'>

				<DatasetItemHeader heading={'Facial expression demo'} subHeading={'Please set up your camera according to the picture on the left'} description={'A web camera is necessary to run this demo. The camera should point at the face of the user. Please point the camera to your face. The goal of this demo is to detect basic emotions, such as anger , disgust, fear, happiness, sadness, surprise and neutral / no emotion.You can start the camera by clicking the play button below.'} />

				<div className='card'>

					<div className='flex flex-col sm:flex-row gap-6 items-stretch'>

						<div className='rounded-lg overflow-hidden aspect-video flex-1'>
							{streaming ?
								(<video ref={videoStreamRef} className='w-full h-full' autoPlay playsInline></video>) :
								(<div className='w-full h-full'><img src='/images/demos/facialExpressionExample.jpg' alt='Facial expression example' /></div>)}
						</div>

						<div className='bg-gray-100 rounded-lg overflow-hidden flex-1'>
							<Webcam className='h-full aspect-video' audio={false} videoConstraints={videoConstraints} />
						</div>

					</div>

				</div>

				{streaming ? (<button className='button text-xl' onClick={handleStopStream}><span className='font-light text-xs'>I'm impressed</span>Stop demo</button>) : (<button className='button text-xl' onClick={handleStartStream}><span className='font-light text-xs'>I'm curious</span>Start demo!</button>)}


			</div>)}

		</div>
	);
}

export default FacialExpressionPage;
