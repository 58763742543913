import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UserLoginService from 'service/user/UserLoginService';
import PersonalDataService from 'service/personalData/PersonalDataService';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup.string().email('E-mail format is wrong').required('E-mail field is required'),
	password: yup.string().required('Password field is required').min(4, 'This password is too short(min. 4 character)')
});

const defaultValues = {
	email: '',
	password: ''
};

function DatasetLogin(props) {
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [loginFailed, setLoginFailed] = useState(false);

	const { control, setValue, formState, handleSubmit, reset, trigger, setError, register } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});
	const { isValid, dirtyFields, errors } = formState;

	useEffect(() => {
		setValue('email', '', { shouldDirty: true, shouldValidate: true });
		setValue('password', '', { shouldDirty: true, shouldValidate: true });
	}, [reset, setValue, trigger]);

	function onSubmit(model) {
		setLoginFailed(false);
		setSubmitDisabled(true);
		UserLoginService.login(model.email, model.password).then(resp => {
			if (resp) {
				PersonalDataService.getLoggedInUserPersonalId().then(personalId => {
					props.onNext(personalId);
				});
			} else {
				setLoginFailed(true);
				setSubmitDisabled(false);
			}
		});
	}

	return (
		<form className='card w-full max-w-lg flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>

			<div className='flex flex-col gap-2'>
				<h1>Login</h1>
				If you have previously registered, please log in with the informations you provided before to continue your participation
			</div>

			<div className='w-full flex flex-col gap-4'>
				<div className='flex flex-col gap-1'>
					<input type='text' className='w-full' placeholder='E-mail' {...register('email')} />
					{errors.email && <div className='input-error-message'>{errors.email.message}</div>}
				</div>

				<div className='flex flex-col gap-1'>
					<input type='password' className='w-full' placeholder='Password' {...register('password')} />
					{errors.password && <div className='input-error-message'>{errors.password.message}</div>}
				</div>
			</div>

			{loginFailed ? <div className='input-error-message'>Invalid e-mail or password.</div> : null}

			<input className='button' type='submit' value='Login' disabled={submitDisabled} />

		</form>
	);
}

export default DatasetLogin;
