import { useEffect, useCallback, useState, useRef } from 'react';
import Webcam from 'react-webcam';

export default function WebsocketTest() {
	const webcamRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const videoStreamRef = useRef(null);
	const websocketRef = useRef(null);
	const [capturing, setCapturing] = useState(false);

	const videoConstraints = {
		facingMode: 'user'
	};

	useEffect(() => {
		websocketRef.current = new WebSocket('wss://creometa.dev.office.creo.hu/video');
		return () => {
			websocketRef.current.close();
		};
	}, []);
	const handleStartCaptureClick = useCallback(() => {
		setCapturing(true);
		mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
			mimeType: 'video/webm'
		});
		mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
		mediaRecorderRef.current.start(250);
	}, [webcamRef, setCapturing, mediaRecorderRef]);

	const handleDataAvailable = useCallback(({ data }) => {
		if (data.size > 0) {
			websocketRef.current.send(data);
		}
	});

	const handleStopCaptureClick = useCallback(() => {
		mediaRecorderRef.current.stop();
		setCapturing(false);
	}, [mediaRecorderRef, webcamRef, setCapturing]);

	return (
		<div className="py-16 p-site-x min-h-screen flex flex-col items-center justify-center bg-sky-700">
			<div className="max-w-boxed flex flex-col gap-6 text-white text-center">
				<div className="flex flex-row">
					<Webcam className="h-full mr-20" audio={false} ref={webcamRef} width={338} height={600} videoConstraints={videoConstraints} />
					<video ref={videoStreamRef} className="rounded-xl overflow-hidden" width="338" height="600" autoPlay playsInline></video>
				</div>
				<div>
					{capturing ? (
						<button className="button" onClick={handleStopCaptureClick}>
							Stop stream
						</button>
					) : (
						<button className="button" onClick={handleStartCaptureClick}>
							Start stream
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
