import { useEffect, useState } from 'react';
import DatasetRegistration from './DatasetRegistration';
import DatasetLogin from './DatasetLogin';
import UserLoginService from 'service/user/UserLoginService';
import PersonalDataService from 'service/personalData/PersonalDataService';
import Loader from 'elements/Loader';

export default function DatasetPersonalData(props) {
	const [authChecked, setAuthChecked] = useState(false);
	const [view, setView] = useState('registration');

	useEffect(() => {
		UserLoginService.isLoggedIn().then(loggedIn => {
			if (loggedIn) {
				PersonalDataService.getLoggedInUserPersonalId().then(personalDataId => props.onNext(personalDataId));
			} else {
				setAuthChecked(true);
			}
		});
	}, []);

	return authChecked ? (<div className='w-full flex flex-col gap-6 items-start max-w-4xl flex-1'>

		<div className='card p-0 w-full'>

			<div className='p-0 w-full flex flex-row rounded-xl overflow-hidden'>
				<CardButton active={view === 'registration'} onClick={() => setView('registration')} label='Registration' />
				<CardButton active={view === 'login'} onClick={() => setView('login')} label='Login' />
			</div>

		</div>

		<div className='w-full flex flex-col items-center justify-center flex-1'>

			{view === 'registration' ? <DatasetRegistration onNext={props.onNext} /> : null}
			{view === 'login' ? <DatasetLogin onNext={props.onNext} /> : null}

		</div>

	</div>) : (<Loader />);
}

function CardButton(props) {
	return (
		<button className={'button rounded-none p-6 flex-1 ' + (props.active ? '' : 'bg-gray-300')} onClick={props.onClick}>{props.label}</button>
	);
}
