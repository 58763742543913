import KeyedAndNamedDto from 'modules/base/dto/KeyedAndNamedDto';

export class DatasetItemDto extends KeyedAndNamedDto {
	constructor() {
		super();
		/** @type {number} */
		this.position = null;
		/** @type {string} */
		this.description = null;
		/** @type {string} */
		this.media = null;
		/** @type {string} */
		this.itemType = null;
	}

	/**
	 * @typedef {Object} _DatasetItemDtoProps
	 * @property {number} position
	 * @property {string} description
	 * @property {string} image
	 * @property {string} itemType
	 * @typedef {import("../KeyedAndNamedDto").KeyedAndNamedDtoProps & _DatasetItemDtoProps} DatasetItemDtoProps
	 */
	/**
	 *
	 * @param {DatasetItemDtoProps} props
	 * @returns {DatasetItemDto}
	 */
	setup(props) {
		super.setup(props);
		this.position = props.position;
		this.description = props.description;
		this.media = props.media;
		this.itemType = props.itemType;
		return this;
	}
}

export default DatasetItemDto;
