import { useState, useRef, useEffect } from 'react';
import { channelNames } from 'muse-js';

function MuseRecording(props) {
	const [recording, setRecording] = useState(false);
	const [samples, setSamples] = useState([]);
	const subscriptionRef = useRef(null);

	useEffect(() => {
		if (subscriptionRef.current) {
			subscriptionRef.current.unsubscribe();
		}
	}, []);

	const handleStartRecording = () => {
		setRecording(true);
		setSamples([]);
		subscriptionRef.current = props.data.subscribe(sample => {
			setSamples(samples => [...samples, [sample.timestamp, ...sample.data]]);
		});
	};

	const handleStopRecording = () => {
		setRecording(false);
		subscriptionRef.current.unsubscribe();
		const a = document.createElement('a');
		const headers = ['time', ...channelNames].join(',');
		const csvData = headers + '\n' + samples.map(item => item.join(',')).join('\n');
		const file = new Blob([csvData], { type: 'text/csv' });
		a.href = URL.createObjectURL(file);
		document.body.appendChild(a);
		a.download = 'recording.csv';
		a.click();
		document.body.removeChild(a);
	};

	return (
		<div className="mb-16">
			<div className="flex flex-row justify-center">
				<button className="button mr-6" disabled={recording} onClick={() => handleStartRecording()}>
					Start recording
				</button>
				<button className="button" disabled={!recording} onClick={() => handleStopRecording()}>
					Stop recording
				</button>
			</div>
		</div>
	);
}

export default MuseRecording;
