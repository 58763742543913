import nBossConnection from 'modules/base/service/nBossConnection';
import DatasetItemDto from 'dto/datasetItem/DatasetItemDto';

class DatasetItemService {
	constructor() {
		this.connection = nBossConnection.connection;
		this.CONTROL = 'datasetItemControl';
	}

	/**
	 * @return {Promise<DatasetItemDto[]>}
	 */
	getList() {
		return new Promise((resolve, reject) => {
			this.connection
				.get(`/${this.CONTROL}/getList`)
				.then(response => resolve(response.data !== null ? response.data.map(d => new DatasetItemDto().setup(d)) : null))
				.catch(reject);
		});
	}
}

export default new DatasetItemService();
