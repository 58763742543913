import { useEffect, useState, useRef } from 'react';
import Webcam from 'react-webcam';

function sdpFilterCodec(kind, codec, realSdp) {
	var allowed = [];
	var rtxRegex = new RegExp('a=fmtp:(\\d+) apt=(\\d+)\r$');
	var codecRegex = new RegExp('a=rtpmap:([0-9]+) ' + escapeRegExp(codec));
	var videoRegex = new RegExp('(m=' + kind + ' .*?)( ([0-9]+))*\\s*$');

	var lines = realSdp.split('\n');

	var isKind = false;
	for (var i = 0; i < lines.length; i++) {
		if (lines[i].startsWith('m=' + kind + ' ')) {
			isKind = true;
		} else if (lines[i].startsWith('m=')) {
			isKind = false;
		}

		if (isKind) {
			var match = lines[i].match(codecRegex);
			if (match) {
				allowed.push(parseInt(match[1]));
			}

			match = lines[i].match(rtxRegex);
			if (match && allowed.includes(parseInt(match[2]))) {
				allowed.push(parseInt(match[1]));
			}
		}
	}

	var skipRegex = 'a=(fmtp|rtcp-fb|rtpmap):([0-9]+)';
	var sdp = '';

	isKind = false;
	for (var i = 0; i < lines.length; i++) {
		if (lines[i].startsWith('m=' + kind + ' ')) {
			isKind = true;
		} else if (lines[i].startsWith('m=')) {
			isKind = false;
		}

		if (isKind) {
			var skipMatch = lines[i].match(skipRegex);
			if (skipMatch && !allowed.includes(parseInt(skipMatch[2]))) {
				continue;
			} else if (lines[i].match(videoRegex)) {
				sdp += lines[i].replace(videoRegex, '$1 ' + allowed.join(' ')) + '\n';
			} else {
				sdp += lines[i] + '\n';
			}
		} else {
			sdp += lines[i] + '\n';
		}
	}

	return sdp;
}

function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const createPeerConnection = useSTUN => {
	var config = {
		sdpSemantics: 'unified-plan'
	};
	if (useSTUN) {
		config.iceServers = [{ urls: ['stun:stun.l.google.com:19302'] }];
	}
	const pc = new RTCPeerConnection(config);

	// register some listeners to help debugging
	pc.addEventListener(
		'icegatheringstatechange',
		function () {
			console.log('[ICEGATHERING]: ' + pc.iceGatheringState);
		},
		false
	);
	console.log('[ICEGATHERING]: ' + pc.iceGatheringState);

	pc.addEventListener(
		'iceconnectionstatechange',
		function () {
			console.log('[ICECONNECTION]: ' + pc.iceConnectionState);
		},
		false
	);
	console.log('[ICECONNECTION]: ' + pc.iceConnectionState);

	pc.addEventListener(
		'signalingstatechange',
		function () {
			console.log('[ICESIGNALING]: ' + pc.signalingState);
		},
		false
	);
	console.log('[ICESIGNALING]: ' + pc.signalingState);
	return pc;
};

const createDataChannel = pc => {
	const parameters = { ordered: true };
	//const parameters = {ordered: false, maxRetransmits: 0};
	//const parameters = {ordered: false, maxPacketLifetime: 500};

	const dc = pc.createDataChannel('chat', parameters);
	dc.onclose = function () {
		console.log('[DATACHANNEL]: close');
	};
	dc.onopen = function () {
		console.log('[DATACHANNEL]: open');
		dc.send('ping');
	};
	dc.onmessage = function (evt) {
		console.log('[DATACHANNEL]:< ' + evt.data);
		if (evt.data.substring(0, 4) === 'pong') {
			setTimeout(function () {
				dc.send('ping');
			}, 5000);
		}
	};
	return dc;
};

const negotiate = pc => {
	return pc
		.createOffer()
		.then(function (offer) {
			return pc.setLocalDescription(offer);
		})
		.then(function () {
			// wait for ICE gathering to complete
			return new Promise(function (resolve) {
				if (pc.iceGatheringState === 'complete') {
					resolve();
				} else {
					function checkState() {
						if (pc.iceGatheringState === 'complete') {
							pc.removeEventListener('icegatheringstatechange', checkState);
							resolve();
						}
					}
					pc.addEventListener('icegatheringstatechange', checkState);
				}
			});
		})
		.then(function () {
			var offer = pc.localDescription;

			/*
            var codec;
			codec = document.getElementById('audio-codec').value;
			if (codec !== 'default') {
				offer.sdp = sdpFilterCodec('audio', codec, offer.sdp);
			}

			codec = document.getElementById('video-codec').value;
			if (codec !== 'default') {
				offer.sdp = sdpFilterCodec('video', codec, offer.sdp);
			}*/

			console.log('[NEGOTIATE OFFER]: ' + offer.sdp);
			return fetch('/offer', {
				body: JSON.stringify({
					sdp: offer.sdp,
					type: offer.type,
					video_transform: 'edges' //none, edges, cartoon, rotate
				}),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			});
		})
		.then(function (response) {
			return response.json();
		})
		.then(function (answer) {
			console.log('[NEGOTIATE ANSWER]: ' + answer.sdp);
			return pc.setRemoteDescription(answer);
		})
		.catch(function (e) {
			alert(e);
		});
};

export default function StreamTest() {
	const [streaming, setStreaming] = useState(false);
	const [useSTUN, setUseSTUN] = useState(false);
	const webcamRef = useRef(null);
	const videoStreamRef = useRef(null);
	const pc = useRef(null);
	const dc = useRef(null);

	const videoConstraints = {
		facingMode: 'user'
	};

	useEffect(() => {
		return () => {
			if (streaming) {
				stopStream();
			}
		};
	}, []);

	const handleStartStream = () => {
		setStreaming(true);
		pc.current = createPeerConnection(useSTUN);
		pc.current.addEventListener('track', function (evt) {
			if (evt.track.kind == 'video') videoStreamRef.current.srcObject = evt.streams[0];
		});
		dc.current = createDataChannel(pc.current);

		navigator.mediaDevices.getUserMedia({ video: true }).then(
			function (stream) {
				stream.getTracks().forEach(function (track) {
					pc.current.addTrack(track, stream);
				});
				return negotiate(pc.current);
			},
			function (err) {
				alert('Could not acquire media: ' + err);
			}
		);
	};

	const handleStopStream = () => {
		setStreaming(false);
		stopStream();
	};

	const stopStream = () => {
		// close data channel
		if (dc.current) {
			dc.current.close();
		}

		// close transceivers
		if (pc.current.getTransceivers) {
			pc.current.getTransceivers().forEach(function (transceiver) {
				if (transceiver.stop) {
					transceiver.stop();
				}
			});
		}

		// close local audio / video
		pc.current.getSenders().forEach(function (sender) {
			sender.track.stop();
		});

		// close peer connection
		setTimeout(function () {
			pc.current.close();
		}, 500);
	};

	return (
		<div className="py-16 p-site-x min-h-screen flex flex-col items-center justify-center bg-sky-700">
			<div className="max-w-boxed flex flex-col gap-6 text-white text-center">
				<div>
					<input id="use-stun" type="checkbox" checked={useSTUN} onChange={e => setUseSTUN(e.target.checked)} />
					<label htmlFor="use-stun">Use STUN server</label>
				</div>
				<div className="flex flex-row">
					<Webcam className="h-full mr-20" audio={false} ref={webcamRef} width={338} height={600} videoConstraints={videoConstraints} />
					<video ref={videoStreamRef} className="rounded-xl overflow-hidden" width="338" height="600" autoPlay playsInline></video>
				</div>
				<div>
					{streaming ? (
						<button className="button" onClick={handleStopStream}>
							Stop stream
						</button>
					) : (
						<button className="button" onClick={handleStartStream}>
							Start stream
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
