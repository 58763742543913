import nBossConnection from 'modules/base/service/nBossConnection';
import { Link } from 'react-router-dom';
import { DatasetItemHeader, MediaScreen } from 'dataset/DatasetPage';

function DatasetFinished() {
	return (
		<div className="w-full max-w-boxed flex flex-col gap-6 items-center justify-center flex-1">
			<DatasetItemHeader heading={'You successfuly completed the recording'} subHeading={'Thank you'} description={'You can download your dataset below if you are curious'} />

			<MediaScreen
				imageContainerClassName="aspect-[4/3]"
				imageChildren={<img className="w-full" src="images/dataset/thank-you-for-your-cooperation.jpg" alt="Thank you for your cooperation!" />}
				buttonChildren={
					<>
						<a className="flex-1 button" href={`${nBossConnection.baseUrl}/datasetControl/getDatasetArchive`}>
							Download my dataset
						</a>
						<Link className="flex-1 button alt" to="/">
							Back to the Home page
						</Link>
					</>
				}
			/>
		</div>
	);
}

export default DatasetFinished;
