import { useState, useRef, useEffect } from 'react';
import { channelNames } from 'muse-js';
import { map, filter, takeUntil, mergeMap, groupBy } from 'rxjs/operators';
import { SmoothieChart, TimeSeries } from 'smoothie';
import Fili from 'fili';

const samplingFrequency = 256;

const bandpass = (samplingFreq, lowFreq, highFreq) => {
	const firCalculator = new Fili.FirCoeffs();
	const Coefficients = firCalculator.bandpass({
		order: 101,
		Fs: samplingFreq,
		F2: lowFreq,
		F1: highFreq
	});
	const filter = new Fili.FirFilter(Coefficients);
	return value => filter.singleStep(value);
};

const getChartSmoothieDefaults = overrides => {
	return {
		...{
			responsive: true,
			millisPerPixel: 5,
			grid: {
				lineWidth: 4,
				fillStyle: 'transparent',
				strokeStyle: 'transparent',
				sharpLines: true,
				verticalSections: 0,
				borderVisible: false
			},
			labels: {
				disabled: true
			}
		},
		...overrides
	};
};

const getColors = () => {
	return [
		{ borderColor: 'rgba(112,185,252,1)', backgroundColor: 'rgba(112,185,252,1)' },
		{ borderColor: 'rgba(116,150,161,1)', backgroundColor: 'rgba(116,150,161,1)' },
		{ borderColor: 'rgba(162,86,178,1)', backgroundColor: 'rgba(162,86,178,1)' },
		{ borderColor: 'rgba(144,132,246,1)', backgroundColor: 'rgba(144,132,246,1)' },
		{ borderColor: 'rgba(138,219,229,1)', backgroundColor: 'rgba(138,219,229,1)' },
		{ borderColor: 'rgba(207,181,59, 1)', backgroundColor: 'rgba(207,181,59, 1)' },
		{ borderColor: 'rgba(148,159,177,1)', backgroundColor: 'rgba(148,159,177,1)' },
		{ borderColor: 'rgba(77,83,96,1)', backgroundColor: 'rgba(77,83,96,1)' }
	];
};

function SmoothieCanvas(props) {
	const canvasRef = useRef(null);

	useEffect(() => {
		props.canvas.streamTo(canvasRef.current);
		return () => {
			//props.canvas.streamTo(null);
			props.canvas.stop();
		};
	}, []);
	return <canvas ref={canvasRef} className="time-series-canvas"></canvas>;
}

function MuseChart(props) {
	const [filterOpt, setFilterOpt] = useState(false);
	const channels = props.enableAux ? 5 : 4;
	const canvases = useRef([]);
	const lines = useRef([]);
	const amplitudes = useRef([]);
	const uVrms = useRef([0, 0, 0, 0, 0]);
	const [uVrmsValues, setuVrmsValues] = useState([0, 0, 0, 0, 0]);
	const uMeans = useRef([0, 0, 0, 0, 0]);
	const colors = getColors();

	useEffect(() => {
		canvases.current = Array(channels)
			.fill(0)
			.map(() => new SmoothieChart(getChartSmoothieDefaults({ millisPerPixel: 8, maxValue: 500, minValue: -500 })));
		lines.current = Array(channels)
			.fill(0)
			.map(() => new TimeSeries());
		addTimeSeries();
		props.data
			.pipe(
				takeUntil(props.destroyRef.current),
				mergeMap(sampleSet =>
					sampleSet.data.slice(0, channels).map((value, electrode) => ({
						timestamp: sampleSet.timestamp,
						value,
						electrode
					}))
				),
				groupBy(sample => sample.electrode),
				mergeMap(group => {
					const bandpassFilter = bandpass(samplingFrequency, 1, 30);
					const conditionalFilter = value => (filterOpt ? bandpassFilter(value) : value);
					return group.pipe(
						filter(sample => !isNaN(sample.value)),
						map(sample => ({ ...sample, value: conditionalFilter(sample.value) }))
					);
				})
			)
			.subscribe(sample => {
				draw(sample.timestamp, sample.value, sample.electrode);
			});
		return () => {
			props.destroyRef.current.next();
		};
	}, []);

	const addTimeSeries = () => {
		lines.current.forEach((line, index) => {
			canvases.current[index].addTimeSeries(line, {
				lineWidth: 2,
				strokeStyle: colors[index].borderColor
			});
		});
	};

	const draw = (timestamp, amplitude, index) => {
		uMeans.current[index] = 0.995 * uMeans.current[index] + 0.005 * amplitude;
		uVrms.current[index] = Math.sqrt(0.995 * uVrms.current[index] ** 2 + 0.005 * (amplitude - uMeans.current[index]) ** 2);
		lines.current[index].append(timestamp, amplitude);
		amplitudes.current[index] = amplitude.toFixed(2);
		setuVrmsValues([...uVrms.current]);
	};

	return (
		<div className="mt-20 mb-20">
			{amplitudes.current.length > 0 ? (
				<section className="time-series">
					{canvases.current.map((canvas, index) => (
						<article className="time-series-channel" key={index}>
							<aside className="time-series-control">
								<div className="time-series-number" style={{ background: colors[index].borderColor }}>
									{` ${channelNames[index]} `}
								</div>
							</aside>
							<SmoothieCanvas canvas={canvas} />
							<aside className="time-series-amplitude" style={{ background: colors[index].borderColor }}>
								{uVrmsValues[index].toFixed(1)} uVrms
							</aside>
						</article>
					))}
				</section>
			) : null}
		</div>
	);
}

export default MuseChart;
