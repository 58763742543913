import CameraAuthComponent from 'components/meta/CameraAuthComponent';
import { useState, useRef } from 'react';
import { DatasetItemHeader } from 'dataset/DatasetPage';
import Webcam from 'react-webcam';

function GesturesPage() {
	const [cameraAuth, setCameraAuth] = useState(false);
	const [streaming, setStreaming] = useState(false);
	const videoStreamRef = useRef(null);

	const handleStartStream = () => {
		setStreaming(true);
	};
	const handleStopStream = () => {
		setStreaming(false);
	};

	const videoConstraints = {
		facingMode: 'user'
	};

	return (
		<div className='pt-32 p-site-x flex-1 flex flex-col items-center justify-center'>

			{!cameraAuth && <CameraAuthComponent onAuth={() => setCameraAuth(true)} />}


			{cameraAuth && (<div className='w-full max-w-boxed flex flex-col gap-6 items-center'>

				<DatasetItemHeader heading={'Gestures demo'} subHeading={'Please set up your camera according to the picture on the left'} description={'A web camera is necessary to run this demo. The camera should be positioned such that the portion of the body from mid-thigh and up should be visible. Please point your camera to your upper body. The goal of this demo is to detect basic gestures, such as pointing, hands crossing, straight or bent posture, over gesticulating, keeping hands at waist level, touching hair and raising hands to face, thumbs up/down, head tilt, touching face, touching nose, hands visibility, palm visibility, folding fingers into fist, keeping hands at waist level, keeping hands near the body while standing, keeping hands behind the body while standing or in pockets, establishing eye contact with the camera, head nodding, smiling, making a grimace or angry face and blinking. You can start the camera by clicking the play button below.'} />

				<div className='card'>

					<div className='flex flex-col sm:flex-row gap-6 items-stretch'>

						<div className='rounded-lg overflow-hidden aspect-video flex-1'>
							{streaming ? (<video ref={videoStreamRef} className='w-full h-full' autoPlay playsInline></video>
							) : (<div className='w-full h-full'>
								<img src='/images/demos/gesturesExample.jpg' alt='Gestures example' />
							</div>)}
						</div>

						<div className='bg-gray-100 rounded-lg overflow-hidden flex-1'>
							<Webcam className='h-full aspect-video' audio={false} videoConstraints={videoConstraints} />
						</div>

					</div>

				</div>

				{streaming ? (<button className='button text-xl' onClick={handleStopStream}><span className='font-light text-xs'>I'm impressed</span>Stop demo</button>) : (<button className='button text-xl' onClick={handleStartStream}><span className='font-light text-xs'>I'm curious</span>Start demo!</button>)}


			</div>)}

		</div>
	);
}

export default GesturesPage;
