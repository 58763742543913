import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import nBossConnection from 'modules/base/service/nBossConnection';
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from 'components/creo/Header';
import Footer from 'components/creo/Footer';

import StartPage from 'page/StartPage';
import DatasetPage from 'dataset/DatasetPage';
import StreamTest from 'page/StreamTest';
import FacialExpressionPage from 'demos/FacialExpressionPage';
import GesturesPage from 'demos/GesturesPage';
import MusePage from 'demos/MusePage';
import MuseIframePage from 'demos/MuseIframePage';
// import ContactUsPage from 'contact/ContactUsPage';
import WebsocketTest from 'page/WebsocketTest';
import MeasurePage from 'measure/MeasurePage';
import WebsocketImageTest from 'page/WebsocketImageTest';

function App() {
	useEffect(() => {
		nBossConnection.setErrorHandler(error => {
			if (typeof error.response.status !== 'undefined' && error.response.status === 500) alert('Internal Server Error!');
			else console.log(error);
		});
	}, []);

	return (
		<ParallaxProvider>
			<Router>
				<div className="relative flex flex-col min-h-screen">
					<Header />

					<div className="flex-1 flex flex-col">
						<Routes>
							<Route path="/streamtest" element={<StreamTest />} />
							<Route path="/wstest" element={<WebsocketTest />} />
							<Route path="/wsimagetest" element={<WebsocketImageTest />} />
							<Route path="/facial-expression" element={<FacialExpressionPage />} />
							<Route path="/gestures" element={<GesturesPage />} />
							<Route path="/muse" element={<MusePage />} />
							<Route path="/muse-test" element={<MuseIframePage />} />
							<Route path="/dataset-participation" element={<DatasetPage />} />
							<Route path="/dataset" element={<StartPage />} />
							<Route path="/demos" element={<StartPage />} />
							<Route path="/contact" element={<StartPage />} />
							<Route path="/measure" element={<MeasurePage />} />
							<Route path="/" element={<StartPage />} />
						</Routes>
					</div>

					<Footer />
				</div>
			</Router>
		</ParallaxProvider>
	);
}

export default App;
