
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
// import { Parallax } from 'react-scroll-parallax';

export default function Hero(props) {
	return (
		<div className={'relative p-site-x min-h-screen flex flex-col items-center justify-center bg-sky-700 ' + (props.className || '')} >

			{/* <Parallax className={'relative p-site-x min-h-screen flex flex-col items-center justify-center bg-sky-700 ' + (props.className || '')} speed={-35}> */}

			<AnimatedBg className='mix-blend-multiply' />

			<motion.div
				className='relative card bg-opacity-10 px-6 sm:px-8 py-8 sm:py-12 backdrop-blur max-w-4xl flex flex-col items-center gap-6 text-white text-center'
				initial={{ scale: 0.5, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{ delay: 1, type: 'spring', stiffness: 100 }}>

				<div className='flex flex-col items-center gap-2'>
					<h1 className='text-xs sm:text-base font-light tracking-widest'>Human</h1>
					<h1 className='text-2xl sm:text-6xl font-extrabold uppercase'>Meta-communication</h1>
					<h1 className='text-xs sm:text-base font-light tracking-widest'>analysis with </h1>
					<h1 className='text-4xl sm:text-4xl font-extrabold uppercase tracking-wider'>artificial intelligence algorithms</h1>
				</div>

				<motion.div className='w-16 sm:w-64 h-2 bg-sky-700'
					initial={{ scale: 0.5, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{ delay: 1.4, type: 'spring', stiffness: 100 }} />

				<div className='text-xs sm:text-sm font-light leading-5 sm:leading-6 mb-4'>
					Creo Meta provides artificial intelligence algorithms to analyze human meta-communication by analyzing video interviews. We detect facial expressions and also identify gestures. It means that we primarily focus on video
					processing. However, we have experience also on other modalities such as EEG signals and written text. Voice tone analysis is provided by a partner.
				</div>

				<motion.div
					className='absolute w-full h-0 bg-red-400 bottom-0 left-0 flex items-center justify-center'
					initial={{ scale: 0.5, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{ delay: 1.8, type: 'spring', stiffness: 100 }}>
					<Link to='/demos' className='button bg-gray-900 hover:bg-white text-white hover:text-gray-900 shadow-xl' >See Our Demos</Link>
				</motion.div>

			</motion.div>

			{/* </Parallax> */}
		</div>

	);
}

function AnimatedBg(props) {
	const imagesList = [
		'url(/images/stock/afraid.jpg)',
		'url(/images/stock/disgusted.jpg)',
		'url(/images/stock/happy.jpg)',
		'url(/images/stock/neutral.jpg)',
		'url(/images/stock/sad.jpg)',
		'url(/images/stock/standing.jpg)',
		'url(/images/stock/surprised.jpg)',
	];

	const [activeIndex, setActiveIndex] = useState(null);

	let getNext = () => { return activeIndex + 1 >= imagesList.length ? 0 : activeIndex + 1; }
	let getPrev = () => { return activeIndex - 1 < 0 ? imagesList.length - 1 : activeIndex - 1; }

	useEffect(() => {
		setActiveIndex(0);
	}, [])

	useEffect(() => {
		let timer = setTimeout(() => {
			setActiveIndex(getNext());
		}, 5 * 1000);
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex])

	return (
		<div className={'absolute w-full h-full top-0 left-0 overflow-hidden ' + (props.className || '')}>

			<motion.div
				className='relative w-full h-full'
				// style={{ originX: 0.5, originY: 0.5, transformPerspective: 1250 }}
				initial={activeIndex % 2 !== 0 ? 'visible' : 'hidden'}
				animate={activeIndex % 2 !== 0 ? 'visible' : 'hidden'}
				transition={{ duration: 1 }}
				variants={{ visible: { scale: 1 }, hidden: { scale: 1.1 } }} >

				<div className='relative w-full h-full'>
					{imagesList.map((item, index) => (
						<motion.img
							key={index}
							className='absolute w-full h-full top-0 left-0 bg-no-repeat bg-center bg-cover' style={{ backgroundImage: item }}
							initial={index === activeIndex ? 'visible' : 'hidden'}
							animate={index === activeIndex ? 'visible' : 'hidden'}
							transition={{ duration: 1 }}
							variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }} />
					))}
				</div>

			</motion.div>

		</div>
	);
}
