
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export function IconLink(props) {
    return (
        <a href={props.href} className='flex gap-1.5 items-center mt-1.5 group hover:text-creo-identity transition-all'>
            {props.icon}
            <div className='border-b border-transparent hover:border-b-creo-identity transition-all'>{props.value}</div>
        </a>
    )
}

// export function UnderlineHoverTile(props) {
//     const [hover, setHover] = useState(false);

//     return (
//         <div className='relative flex'
//             onMouseEnter={() => setHover(true)}
//             onMouseLeave={() => setHover(false)}>

//             {props.children}

//             <motion.div
//                 className='absolute bottom-0 left-0 right-0 h-px w-full bg-red-400'
//                 initial={hover ? 'visible' : 'hidden'}
//                 animate={hover ? 'visible' : 'hidden'}
//                 // transition={{ duration: 1 }}
//                 variants={{ visible: { opacity: 1 }, hidden: { opacity: 1 } }} />

//         </div>
//     );
// }