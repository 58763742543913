import { useEffect, useState } from 'react';
import DatasetIntro from './steps/DatasetIntro';
import DatasetItemIntro from './steps/DatasetItemIntro';
import DatasetItem from './steps/DatasetItem';
import DatasetItemImage from './steps/DatasetItemImage';
import DatasetPersonalData from './steps/DatasetPersonalData';
import DatasetCameraAuthorize from './steps/DatasetCameraAuthorize';
import DatasetItemService from 'service/datasetItem/DatasetItemService';
import DatasetDone from './steps/DatasetDone';
import PersonalDataService from 'service/personalData/PersonalDataService';
import DatasetService from 'service/dataset/DatasetService';
import DatasetFinished from './steps/DatasetFinished';
import DatasetItemSelector from './DatasetItemSelector';
import DatasetProfileMenu from './steps/DatasetProfileMenu';
import { DatasetDto } from 'dto/dataset/DatasetDto';
import Loader from 'elements/Loader';
import UserLoginService from 'service/user/UserLoginService';
import DatasetModifyProfile from './steps/DatasetModifyProfile';
import DatasetDownloadNotAvailable from './steps/DatasetDownloadNotAvailable';

export default function DatasetPage() {
	const [step, setStep] = useState('loading');
	const [personalDataId, setPersonalDataId] = useState(null);
	const [datasetItemList, setDatasetItemList] = useState([]);
	const [personalItemList, setPersonalItemList] = useState([]);
	const [currentItemType, setCurrentItemType] = useState('EMOTION');
	const [currentItemPos, setCurrentItemPos] = useState(1);
	const [currentItem, setCurrentItem] = useState(null);
	const [isLast, setIsLast] = useState(false);
	const [anyItemAvailable, setAnyItemAvailable] = useState(true);

	useEffect(() => {
		DatasetItemService.getList().then(resp => {
			setDatasetItemList(resp);
			setStep('intro');
		});
	}, []);

	const handleNextItem = () => {
		const nextAvailable = datasetItemList.findIndex(d => d.itemType === currentItemType && d.position === currentItemPos + 1);
		const anyAvailable = datasetItemList.find(d => d.position > 0 && personalItemList.findIndex(i => i.item === d.id) === -1);
		if (anyAvailable) {
			if (nextAvailable > -1) {
				setCurrentItemPos(currentItemPos + 1);
			} else {
				if (currentItemType === 'EMOTION') {
					setCurrentItemType('GESTURE');
					setCurrentItemPos(1);
					setStep('itemIntro');
				} else {
					if (anyAvailable) {
						setCurrentItemType(anyAvailable.itemType);
						setCurrentItemPos(anyAvailable.position);
					} else {
						setStep('done');
					}
				}
			}
		} else {
			setStep('done');
		}
	};

	const handleRestart = () => {
		setStep('intro');
		setCurrentItemType('EMOTION');
		setCurrentItemPos(1);
	};

	const handlePersonalData = id => {
		setStep('loading');
		setPersonalDataId(id);
		PersonalDataService.isFinished().then(finished => {
			if (finished) {
				setStep('finished');
				setAnyItemAvailable(false);
			} else {
				DatasetService.getItems().then(items => {
					setPersonalItemList(items);
					setIsLast(items.length > datasetItemList.length - 3);
					setStep('cameraAuth');
				});
			}
		});
	};
	const handleSubmittedData = data => {
		const dto = new DatasetDto().setup(data);
		const index = personalItemList.findIndex(d => d.id === dto.id);
		if (index > -1) {
			setPersonalItemList(personalItemList.map(d => (d.id === dto.id ? dto : d)));
		} else {
			setPersonalItemList([...personalItemList, ...[dto]]);
		}
	};

	const handleLogout = () => {
		UserLoginService.logout().then(() => {
			setPersonalDataId(null);
			setPersonalItemList([]);
			setStep('intro');
			setCurrentItemType('EMOTION');
			setCurrentItemPos(1);
			setCurrentItem(false);
			setIsLast(false);
		});
	};

	useEffect(() => {
		if (datasetItemList) setCurrentItem(datasetItemList.find(d => d.itemType === currentItemType && d.position === currentItemPos));
	}, [currentItemType, currentItemPos, datasetItemList]);

	useEffect(() => {
		setAnyItemAvailable(personalItemList.length < datasetItemList.length - 2);
	}, [personalItemList]);

	return (
		<div className="pt-32 p-site-x flex-1 flex flex-col items-center justify-center bg-no-repeat bg-center bg-cover " /* style={{ backgroundImage: 'url(/images/stock/test.png)' }}*/>
			{step === 'loading' && <Loader />}

			{step === 'intro' && <DatasetIntro onNext={() => setStep('personalData')} />}

			{step === 'personalData' ? (
				<DatasetPersonalData
					onNext={id => {
						handlePersonalData(id);
					}}
				/>
			) : null}

			{step === 'cameraAuth' ? <DatasetCameraAuthorize onNext={() => setStep('itemIntro')} /> : null}

			{step === 'itemIntro' ? <DatasetItemIntro data={datasetItemList.find(d => d.itemType === currentItemType && d.key === 'intro')} onNext={() => setStep('item')} /> : null}

			{step === 'item' && currentItemType === 'EMOTION' && currentItem ? (
				<DatasetItemImage
					data={currentItem}
					submittedData={personalItemList.find(d => d.item === currentItem.id)}
					list={datasetItemList}
					personalDataId={personalDataId}
					onSubmitted={d => handleSubmittedData(d)}
					onNext={() => handleNextItem()}
					isLast={isLast}
				/>
			) : null}

			{step === 'item' && currentItemType === 'GESTURE' && currentItem ? (
				<DatasetItem
					data={currentItem}
					submittedData={personalItemList.find(d => d.item === currentItem.id)}
					list={datasetItemList}
					personalDataId={personalDataId}
					onSubmitted={d => handleSubmittedData(d)}
					onNext={() => handleNextItem()}
					isLast={isLast}
				/>
			) : null}

			{step === 'done' ? <DatasetDone onRestart={() => handleRestart()} /> : null}

			{step === 'finished' ? <DatasetFinished /> : null}

			{step === 'download_not_available' ? <DatasetDownloadNotAvailable onBackToRecording={() => setStep('item')} /> : null}

			{step === 'item' || step === 'itemIntro' || step === 'myProfile' || step === 'download_not_available' ? (
				<DatasetItemSelector
					itemList={datasetItemList}
					peronsalItemList={personalItemList}
					currentItem={currentItem}
					step={step}
					onItemClick={(type, pos) => {
						setStep('item');
						setCurrentItemType(type);
						setCurrentItemPos(pos);
					}}
				/>
			) : null}

			{step === 'myProfile' ? <DatasetModifyProfile onNext={() => setStep('item')} /> : null}
			{personalDataId !== null ? <DatasetProfileMenu onLogout={() => handleLogout()} onMyProfile={() => setStep('myProfile')} onDownload={() => setStep(anyItemAvailable ? 'download_not_available' : 'finished')} /> : null}
		</div>
	);
}

export function DatasetItemHeader(props) {
	return (
		<div className="w-full flex flex-col gap-2 items-center">
			<div className={'flex flex-col  items-center text-center ' + (props.description ? 'gap-2' : '')}>
				{props.subHeading && <div className="uppercase text-sm">{props.subHeading}</div>}
				<h1 className={props.heading.length > 25 ? 'text-4xl' : ''}>{props.heading}</h1>
			</div>

			{props.description && <div className={'text-center ' /*+ (props.description.length > 255 ? 'text-base' : 'text-base')*/}>{props.description}</div>}

			{props.error ? <div className="input-error-message">Something was broken while submitting the media :(</div> : null}
		</div>
	);
}

export function MediaScreen(props) {
	return (
		<div className="w-full max-w-3xl card p-0">
			<div className={'w-full rounded-t-xl bg-gray-100 overflow-hidden ' + (props.imageContainerClassName || 'aspect-[4/3] sm:aspect-[16/9]')}>{props.imageChildren}</div>

			{props.buttonChildren && <div className="w-full p-4 sm:p-6 flex flex-col sm:flex-row gap-4 justify-center">{props.buttonChildren}</div>}
		</div>
	);
}
