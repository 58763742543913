import nBossConnection from 'modules/base/service/nBossConnection';
import Webcam from 'react-webcam';
import { DatasetItemHeader } from 'dataset/DatasetPage';

export default function DatasetItemIntro(props) {
	const videoConstraints = {
		facingMode: 'user'
	};

	return (
		<div className='w-full max-w-boxed flex flex-col gap-6 items-center'>

			<DatasetItemHeader heading={props.data.name} subHeading={'Please set up your camera according to the picture'} description={'The camera should point at the face of the user or at least should present a video of the face of the user'} />

			<div className='card'>

				<div className='flex flex-col sm:flex-row gap-6'>
					{props.data.media ? <img className='rounded-lg overflow-hidden aspect-video flex-1' src={`${nBossConnection.baseUrl}fileRepository/getFile?key=${props.data.media}`} alt={props.data.name} /> : null}

					<div className='bg-gray-100 rounded-lg overflow-hidden flex-1'>
						<Webcam className='h-full aspect-video' audio={false} videoConstraints={videoConstraints} />
					</div>
				</div>

			</div>

			<button className='button text-3xl' onClick={() => props.onNext()}><span className='font-light text-xs'>It looks about the same</span>Let's begin!</button>

		</div>
	);
}