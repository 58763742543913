import { motion } from 'framer-motion';

function DatasetIntro(props) {
	return (
		<motion.div
			className='relative card bg-sky-700 max-w-4xl flex flex-col gap-6 px-6 sm:px-8 py-8 sm:py-12 items-center text-center text-white'
			initial={{ scale: 0.5, opacity: 0 }}
			animate={{ scale: 1, opacity: 1 }}
			transition={{ delay: 0, type: 'spring', stiffness: 100 }}>

			{/* <h1>Machine learning algorithms learn by example.</h1> */}

			<div className='flex flex-col items-center gap-2'>
				{/* <h1 className='text-xs sm:text-base font-light tracking-widest'>Human</h1> */}
				<h1 className='text-2xl sm:text-4xl font-extrabold uppercase'>Machine learning algorithms</h1>
				<h1 className='text-xs sm:text-base font-light tracking-widest'>learn by</h1>
				<h1 className='text-4xl sm:text-7xl font-extrabold uppercase tracking-wider'>example</h1>
			</div>

			<div className='mb-4'>
				It means high quality data is necessary to build high quality algorithms. Unfortunately, the necessary datasets to conduct meta-communication analysis are not available publicly.
				This is the reason why we decided to build our own dataset. It would be nice if you could participate in the dataset, and help us to improve our algorithms.
			</div>

			<motion.div
				className='absolute w-full h-0 bg-red-400 bottom-0 left-0 flex items-center justify-center'
				initial={{ scale: 0.5, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{ delay: 0.4, type: 'spring', stiffness: 100 }}>
				<button onClick={() => props.onNext()} className='button bg-gray-900 hover:bg-white text-white hover:text-gray-900 shadow-xl'>I would like to participate</button>
			</motion.div>

		</motion.div>
	);
}

export default DatasetIntro;
