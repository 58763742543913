import _ from '@lodash';
import { useRef, useEffect, Fragment } from 'react';

function MuseWS(props) {
	const websocketRef = useRef(null);

	useEffect(() => {
		websocketRef.current = new WebSocket('wss://creometa.dev.office.creo.hu/eeg');
		websocketRef.current.addEventListener('message', function (event) {
			try {
				const message = _.isString(event.data) ? JSON.parse(event.data) : event.data;
				if (!_.isUndefined(props.onEegMessage)) {
					props.onEegMessage(message);
				}
			} catch (err) {
				console.log('[Invalid Incoming EEG message]');
				console.log(event.data);
			}
		});
		return () => {
			websocketRef.current.close();
		};
	}, []);

	useEffect(() => {
		if (props.data !== null) {
			props.data.subscribe(d => {
				if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
					websocketRef.current.send(JSON.stringify(d));
				}
			});
		}
	}, [props.data]);

	return <Fragment></Fragment>;
}

export default MuseWS;
