import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ContactService from 'service/contact/ContactService';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Icons from 'react-icons/fa';

// import { IconLink } from 'components/creo/Common';
// import { UnderlineHoverTile } from 'components/creo/Common';

export default function Contact(props) {
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [thanksView, setThanksView] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        control
    } = useForm({ mode: 'onChange', defaultValues: {} });

    const onSubmit = data => {
        setSubmitDisabled(true);
        ContactService.save(data).then(response => {
            if (response.successful) {
                setThanksView(true);
            } else {
                setSubmitDisabled(false);
                Object.keys(response.properties).forEach(key => {
                    if (response.properties[key].length > 0) {
                        setError(key, {
                            type: 'manual',
                            message: response.properties[key][0].message
                        });
                    }
                });
            }
        });
    };

    return (
        <div className={'py-24 p-site-x flex items-center justify-center bg-gradient-to-b from-gray-100 to-white ' + (props.className || '')} id='contact'>


            <div className='w-full max-w-boxed flex flex-col sm:flex-row items-stretch gap-8 sm:gap-16'>

                <div className='max-w-[27rem] flex flex-col gap-8 bg-red-4000 justify-between'>

                    <div className='flex flex-col items-center sm:items-start gap-4'>
                        <h1>Contact us!</h1>
                        {/* <div className='w-full h-2 bg-sky-700' /> */}
                        <div className='w-32 sm:w-full h-2 bg-sky-700' />
                        <div className='text-center sm:text-left text-sm'>Write to us using the contact form,<br />or contact us at one of the contact details below.</div>
                    </div>

                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2 items-center sm:items-start'>
                            <div className='flex flex-col sm:flex-row gap-2 items-center'>
                                <img src='images/creo-dots.svg' alt='Creo Group' className='w-14' />
                                <div className='text-xl font-extrabold'>creo.hu Kft.</div>
                            </div>
                            <div className='text-xxs text-center sm:text-left'>Creo Group is a software developer company with a strong competence in machine learning and a special interest in the medtech sector. We have our roots in business software development with big names in our portfolio. Regarding software development, we are trained to cover complex business workflows and are designated to simplify them. We also offer our web development services to deliver fancy and high quality browser based systems. Smartphone development is a must have, we cover the main mobile platforms.</div>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <DataLine orbClassName='bg-[#f47721]' icon={<Icons.FaMapMarkerAlt />} label='Address' value='1088 Budapest, Rákóczi str. 19. 3/1a' href='https://www.google.com/maps/place/Budapest,+R%C3%A1k%C3%B3czi+%C3%BAt+19,+1088/@47.495193,19.0625303,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc5d397dde0d:0xf3eef5168d44cc91!8m2!3d47.495193!4d19.064719' />
                            <DataLine orbClassName='bg-[#8bc43f]' icon={<Icons.FaGlobeAfrica />} label='Web' value='www.creo.hu' href='https://www.creo.hu/' />
                            <DataLine orbClassName='bg-[#42c8f4]' icon={<Icons.FaEnvelope />} label='E-mail' value='info@creo.hu' href='mailto:info@creo.hu' />
                            <DataLine orbClassName='bg-[#959593]' icon={<Icons.FaMobileAlt />} label='Phone' value='+36 1 338 1739' href='tel:+3613381739' />
                        </div>
                    </div>

                </div>

                <div className='flex-1'>

                    {thanksView && <Feedback />}

                    {!thanksView && <form className=' w-full flex flex-col gap-0 items-center' onSubmit={handleSubmit(onSubmit)}>

                        <div className='card w-full max-w-boxed flex flex-col gap-4'>

                            <div className='flex flex-col sm:flex-row gap-4'>
                                <div className='flex-1 flex flex-col gap-1'>
                                    <input type='text' className='w-full' placeholder='Your name *' {...register('name')} />
                                    {errors.name && <div className='input-error-message'>{errors.name.message}</div>}
                                </div>

                                <div className='flex-1 flex flex-col gap-1'>
                                    <input type='text' className='w-full' placeholder='Your e-mail address *' {...register('email')} />
                                    {errors.email && <div className='input-error-message'>{errors.email.message}</div>}
                                </div>
                            </div>

                            <div className='flex flex-col sm:flex-row gap-4'>
                                <div className='flex-1 flex flex-col gap-1'>
                                    <input type='text' className='w-full' placeholder='Your phone number' {...register('phone')} />
                                    {errors.email && <div className='input-error-message'>{errors.email.message}</div>}
                                </div>

                                <div className='flex-1 flex flex-col gap-1'>
                                    <input type='text' className='w-full' placeholder='Subject of the message' {...register('subject')} />
                                    {errors.subject && <div className='input-error-message'>{errors.subject.message}</div>}
                                </div>
                            </div>

                            <div className='flex flex-col gap-1'>
                                <textarea className='w-full' placeholder='Your message to us * ...' {...register('message')} style={{ height: 200, resize: 'none' }} />
                                {errors.message && <div className='input-error-message'>{errors.message.message}</div>}
                            </div>

                            <div className='flex flex-col gap-1 items-center justify-center'>
                                <Controller className='bg-red-400' control={control} name='token' render={({ field: { onChange, value } }) => <ReCAPTCHA className='' sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={t => onChange(t)} />} />
                                {errors.token && <div className='input-error-message'>{errors.token.message}</div>}
                            </div>

                            {/* <HintedInput /> */}

                            <input className='button' type='submit' value='Submit' disabled={submitDisabled} />

                        </div>



                    </form>}

                </div>

            </div>


        </div>
    );
}

function Feedback(props) {
    return (<div className=' flex flex-col gap-6 items-stretch'>Thank you for your feedback!</div>);
}

function DataLine(props) {
    return (
        <a href={props.href} className='flex flex-col sm:flex-row items-center sm:items-start justify-center sm:justify-start gap-3 group'>
            <div className={'rounded-full w-10 h-10 flex items-center justify-center text-sm text-white group-hover:rotate-[360deg] transition-all ' + (props.orbClassName || '')}>
                {props.icon}
            </div>
            <div className='items-center sm:items-start text-center sm:text-left'>
                <div className='font-extrabold uppercase'>{props.label}</div>
                <div href={props.href} className='group-hover:underline'>{props.value}</div>
            </div>
        </a>
    );
}